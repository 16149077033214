<template>
    <div class="delivery_info_form_wrapper">
        <!-- START:: TITLE -->
        <h3 class="form_title">{{ $t("TITLES.delivery_info") }}</h3>
        <!-- END:: TITLE -->

        <!-- START:: FORM -->
        <form @submit.prevent="validateFormInputs">
            <div class="row">
                <!-- <div class="input_wrapper imaged_radio my-3">
                    <div class="form-check">
                        <div class="check_wrapper">
                            <input
                                id="visa"
                                class="form-check-input"
                                type="radio"
                                name="payment_card_select"
                                value="visa"
                                v-model="deliveryInfo.cardType"
                            />
                            <label class="form-check-label" for="visa">
                                <img
                                    src="../../assets/media/payment/visa.svg"
                                    alt="Visa Logo"
                                    width="90"
                                    height="60"
                                />
                            </label>
                        </div>

                        <div class="check_wrapper">
                            <input
                                id="instapay"
                                class="form-check-input"
                                type="radio"
                                name="payment_card_select"
                                value="instapay"
                                v-model="deliveryInfo.cardType"
                            />

                            <label class="form-check-label" for="instapay">
                                <img
                                    src="../../assets/media/icons/instapay.png"
                                    alt="MasterCard Logo"
                                    width="90"
                                    height="60"
                                />
                            </label>
                        </div>
                    </div>
                </div> -->
                <!-- START:: PAYMENT CARD SELECT -->
                <div class="input_wrapper imaged_radio my-3">
                    <div
                        class="form-check"
                        v-if="!getShoppingCartItems.show_cash"
                    >
                        <div class="check_wrapper" v-if="userType == 'teacher'">
                            <input
                                id="wallet"
                                class="form-check-input"
                                type="radio"
                                name="payment_type_select"
                                value="wallet"
                                v-model="deliveryInfo.paymentType"
                            />

                            <label class="form-check-label" for="wallet">
                                <img
                                    src="../../assets/media/payment/wallet.svg"
                                    alt="Wallet Logo"
                                    width="90"
                                    height="60"
                                />
                            </label>
                        </div>

                        <div class="check_wrapper">
                            <input
                                id="visa"
                                class="form-check-input"
                                type="radio"
                                name="payment_type_select"
                                value="visa"
                                v-model="deliveryInfo.paymentType"
                            />
                            <label class="form-check-label" for="visa">
                                <img
                                    src="../../assets/media/payment/visa.svg"
                                    alt="Visa Logo"
                                    width="90"
                                    height="60"
                                />
                            </label>
                        </div>

                        <!-- <div class="check_wrapper">
                            <input
                                id="cash"
                                class="form-check-input"
                                type="radio"
                                name="payment_type_select"
                                value="cash"
                                v-model="deliveryInfo.paymentType"
                            />

                            <label class="form-check-label" for="cash">
                                <img
                                    src="../../assets/media/payment/cash.svg"
                                    alt="Cash Logo"
                                    width="90"
                                    height="60"
                                />
                            </label>
                        </div> -->

                        <div class="check_wrapper">
                            <input
                                id="instapay"
                                class="form-check-input"
                                type="radio"
                                name="payment_type_select"
                                value="vodafone_cash"
                                v-model="deliveryInfo.paymentType"
                            />

                            <label class="form-check-label" for="instapay">
                                <img
                                    src="../../assets/media/icons/instapay.png"
                                    alt="Cash Logo"
                                    width="90"
                                    height="60"
                                />
                            </label>
                        </div>
                    </div>
                </div>
                <!-- END:: PAYMENT CARD SELECT -->

                <!--Instapay-->
                <div class="instapay">
                    <h4 class="radio_title">
                        {{ $t("TITLES.instapay") }}
                    </h4>
                    <div class="insta_media">
                        <!--Logo-->
                        <div class="insta_logo">
                            <div class="image">
                                <img
                                    src="../../assets/media/icons/instapay.png"
                                />
                            </div>
                            <div class="desc" v-if="instaNumber">
                                <h4>
                                    {{ $t("TITLES.auccount_num") }}
                                </h4>
                                <span>{{ instaNumber.insta_pay }}</span>
                            </div>
                        </div>
                        <!--Icon Copy-->
                        <button
                            type="button"
                            @click="copyText"
                            class="copy_btn"
                        >
                            <i class="fas fa-copy"></i>
                        </button>
                    </div>
                </div>
                <!--Instapay-->

                <!-- START:: CITY INPUT -->
                <div class="col-lg-6 my-3">
                    <div class="input_wrapper select_wrapper">
                        <multiselect
                            v-model="deliveryInfo.city"
                            track-by="name"
                            label="name"
                            :placeholder="$t('PLACEHOLDERS.city')"
                            :options="cities"
                            :searchable="true"
                            :allow-empty="false"
                            :show-labels="false"
                        >
                        </multiselect>
                    </div>
                </div>
                <!-- END:: CITY INPUT -->

                <!-- START:: DISTRICT INPUT -->
                <div class="col-lg-6 my-3">
                    <div class="input_wrapper">
                        <input
                            type="text"
                            class="form-control"
                            :placeholder="$t('PLACEHOLDERS.district')"
                            v-model.trim="deliveryInfo.district"
                        />
                    </div>
                </div>
                <!-- END:: DISTRICT INPUT -->

                <!-- START:: STREET INPUT -->
                <div class="col-lg-6 my-3">
                    <div class="input_wrapper">
                        <input
                            type="text"
                            class="form-control"
                            :placeholder="$t('PLACEHOLDERS.street')"
                            v-model.trim="deliveryInfo.street"
                        />
                    </div>
                </div>
                <!-- END:: STREET INPUT -->

                <!-- START:: PHONE INPUT -->
                <div class="col-lg-6 my-3">
                    <div class="input_wrapper">
                        <input
                            type="number"
                            class="form-control"
                            :placeholder="$t('PLACEHOLDERS.another_phone')"
                            v-model.trim="deliveryInfo.another_phone"
                        />
                    </div>
                </div>
                <!-- END:: PHONE INPUT -->

                <!-- START:: MESSAGE INPUT -->
                <div class="col-lg-12 my-3">
                    <div class="input_wrapper">
                        <textarea
                            class="form-control"
                            :placeholder="$t('PLACEHOLDERS.additional_info')"
                            v-model.trim="deliveryInfo.additional_info"
                            rows="3"
                        ></textarea>
                    </div>
                </div>
                <!-- END:: MESSAGE INPUT -->

                <template v-if="deliveryInfo.paymentType == 'vodafone_cash'">
                    <!-- START:: SUBJECT UPLOADED INPUT -->
                    <div class="col-12 my-3">
                        <div class="single_image_input_wrapper">
                            <div class="wrapper">
                                <label for="user_avatar">
                                    <!-- <i class="fal fa-camera-alt"></i> -->
                                    صورة التحويل
                                </label>
                                <img
                                    class="user_avatar"
                                    :src="deliveryInfo.image.bath"
                                    alt="User Avatar"
                                    width="150"
                                    height="150"
                                />
                                <input
                                    type="file"
                                    accept="image/png, image/jpg, image/jpeg"
                                    class="form-control"
                                    id="user_avatar"
                                    :placeholder="
                                        $t('PLACEHOLDERS.transfer_image')
                                    "
                                    @change="selectUploadedImage"
                                />
                            </div>
                        </div>
                    </div>
                    <!-- END:: SUBJECT UPLOADED INPUT -->
                    <div class="input_wrapper select_wrapper my-3">
                        <input
                            type="number"
                            class="form-control"
                            :placeholder="$t('PLACEHOLDERS.transfer_from_num')"
                            v-model.trim="deliveryInfo.transfer_from_num"
                        />
                    </div>
                </template>

                <!-- START:: SUBMIT BUTTON WRAPPER -->
                <div class="col-12 btn_wrapper my-3">
                    <button class="w-100 mt-0">
                        {{ $t("BUTTONS.confirm_payment") }}
                        <span class="btn_loader" v-if="isWaitingRequest"></span>
                    </button>
                </div>
                <!-- END:: SUBMIT BUTTON WRAPPER -->
            </div>
        </form>
        <!-- END:: FORM -->
    </div>
</template>

<script>
// START:: IMPORTING VUEX HELPERS
import { mapActions, mapGetters } from "vuex";
import image_path from "../../assets/media/images/upload_image.png";
// END:: IMPORTING VUEX HELPERS

export default {
    name: "DeliveryInfo",
    props: ["cartData"],

    computed: {
        // START:: VUEX GET AUTHENTICATED USER DATA
        ...mapGetters("AuthenticationModule", ["getAuthenticatedUserData"]),
        // END:: VUEX GET AUTHENTICATED USER DATA

        // START:: VUEX GET CART ITEMS
        ...mapGetters("StoreAndWishlistModule", ["getShoppingCartItems"]),
        // END:: VUEX GET CART ITEMS
    },

    data() {
        return {
            // START:: LOADER HANDLING DATA
            isWaitingRequest: false,
            // END:: LOADER HANDLING DATA

            // START:: USER TYPE
            userType: localStorage.getItem("elmo3lm_elmosa3d_user_type"),
            // END:: USER TYPE

            // START:: CITIES DATA
            cities: [],
            // END:: CITIES DATA

            instaNumber: null,

            // START:: DELIVERY INFO
            deliveryInfo: {
                image: {
                    bath: image_path,
                    file: null,
                },
                cardType: null,
                paymentType: null,
                city: null,
                district: null,
                street: null,
                another_phone: null,
                additional_info: null,
            },
            // END:: DELIVERY INFO
        };
    },

    methods: {
        // START:: VUEX REMOVE ITEM FROM SHOPPING CART
        ...mapActions("StoreAndWishlistModule", ["getCart"]),
        // END:: VUEX REMOVE ITEM FROM SHOPPING CART

        // START:: GET CITES
        getCites() {
            this.$axios
                .get("cities", {
                    headers: {
                        Authorization:
                            "Bearer " +
                            localStorage.getItem("elmo3lm_elmosa3d_user_token"),
                        "Accept-language": localStorage.getItem(
                            "elmo3lm_elmosa3d_app_lang"
                        ),
                        "cache-control": "no-cache",
                        Accept: "application/json",
                    },
                })
                .then((res) => {
                    this.cities = res.data.data;
                });
        },
        // END:: GET CITES

        // START:: VALIDATE FORM INPUTS
        validateFormInputs() {
            this.isWaitingRequest = true;

            if (
                !this.deliveryInfo.paymentType &&
                this.getShoppingCartItems.show_cash
            ) {
                this.isWaitingRequest = false;
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.select_payment_type"),
                    messageSize: "22",
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            } else if (!this.deliveryInfo.city) {
                this.isWaitingRequest = false;
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.select_city"),
                    messageSize: "22",
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            } else if (!this.deliveryInfo.district) {
                this.isWaitingRequest = false;
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.district"),
                    messageSize: "22",
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            } else if (!this.deliveryInfo.street) {
                this.isWaitingRequest = false;
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.street"),
                    messageSize: "22",
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            } else if (
                !this.deliveryInfo.image.file &&
                this.deliveryInfo.paymentType == "vodafone_cash"
            ) {
                this.isWaitingRequest = false;
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.transfer_image"),
                    messageSize: "22",
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            } else if (
                !this.deliveryInfo.transfer_from_num &&
                this.deliveryInfo.paymentType == "vodafone_cash"
            ) {
                this.isWaitingRequest = false;
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.transfer_from_num"),
                    messageSize: "22",
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            } else {
                if (this.deliveryInfo.paymentType == "visa") {
                    this.payMob();
                } else if (this.deliveryInfo.paymentType == "vodafone_cash") {
                    this.submitForm();
                }
            }
        },
        // END:: VALIDATE FORM INPUTS

        payMob() {
            // START:: SEND REQUEST
            const theData = new FormData();
            theData.append("price", this.cartData?.price?.total_with_vat);
            theData.append(
                "redirect_url",
                `${window.location.origin}/checkout?cardType=${
                    !this.getShoppingCartItems.show_cash
                        ? "visa"
                        : this.deliveryInfo.paymentType
                } &&city_id=${this.deliveryInfo.city.id} &&neighborhood=${
                    this.deliveryInfo.district
                } &&street=${this.deliveryInfo.street} &&phone=${
                    this.deliveryInfo.another_phone
                } &&addition_data=${this.deliveryInfo.additional_info}`
            );
            // START:: APPEND CONTACT DATA
            // START:: SEND REQUEST
            this.$axios
                .post("website_paymob", theData, {
                    headers: {
                        Authorization:
                            "Bearer " +
                            localStorage.getItem("elmo3lm_elmosa3d_user_token"),
                        "Accept-language": localStorage.getItem(
                            "elmo3lm_elmosa3d_app_lang"
                        ),
                        "cache-control": "no-cache",
                        Accept: "application/json",
                    },
                })
                .then((res) => {
                    window.location.href = res.data.data;
                })
                .catch((err) => {
                    this.$iziToast.error({
                        timeout: 2000,
                        message: err.response.data.message,
                        messageSize: "22",
                        position: this.$t("iziToastConfigs.position"),
                        rtl: this.$t("iziToastConfigs.dir"),
                    });
                    return;
                });
            // END:: FAV CLICK
        },

        // START:: SELECT UPLOADED IMAGE
        selectUploadedImage(e) {
            this.deliveryInfo.image.bath = URL.createObjectURL(
                e.target.files[0]
            );
            this.deliveryInfo.image.file = e.target.files[0];
        },
        // END:: SELECT UPLOADED IMAGE

        // START:: SUBMIT FORM
        submitForm() {
            this.isWaitingRequest = true;

            const theData = new FormData();
            // START:: APPEND DATA
            // if (!this.getShoppingCartItems.show_cash) {
            //     theData.append("pay_type", "visa");
            // } else {
            //     theData.append("pay_type", this.$route.query.cardType);
            // }
            theData.append("pay_type", this.deliveryInfo.paymentType);
            if (this.deliveryInfo.paymentType == "visa") {
                theData.append("city_id", this.$route.query.city_id);
                theData.append("neighborhood", this.$route.query.neighborhood);
                theData.append("street", this.$route.query.street);
                theData.append("phone", this.$route.query.phone);
                theData.append(
                    "addition_data",
                    this.$route.query.addition_data
                );
                if (this.deliveryInfo.paymentType == "visa") {
                    theData.append("transactionId", this.$route.query.id);
                }
            } else {
                theData.append("city_id", this.deliveryInfo.city?.id);
                theData.append("neighborhood", this.deliveryInfo.district);
                theData.append("street", this.deliveryInfo.street);
                theData.append("phone", this.deliveryInfo.another_phone);
                theData.append(
                    "addition_data",
                    this.deliveryInfo.additional_info
                );
            }
            // END:: APPEND DATA

            // START:: SEND REQUEST
            this.$axios
                .post("user/order", theData, {
                    headers: {
                        Authorization:
                            "Bearer " +
                            localStorage.getItem("elmo3lm_elmosa3d_user_token"),
                        "Accept-language": localStorage.getItem(
                            "elmo3lm_elmosa3d_app_lang"
                        ),
                        "cache-control": "no-cache",
                        Accept: "application/json",
                    },
                })
                .then(() => {
                    this.isWaitingRequest = false;

                    this.$iziToast.success({
                        timeout: 2000,
                        message: this.$t("MESSAGES.paied_successfully"),
                        messageSize: "22",
                        position: this.$t("iziToastConfigs.position"),
                        rtl: this.$t("iziToastConfigs.dir"),
                    });
                    this.clearPaymentFormData();
                    this.getCart();
                    this.$router.replace("/my-orders");
                    return;
                })
                .catch((err) => {
                    this.isWaitingRequest = false;

                    this.$iziToast.error({
                        timeout: 2000,
                        position: this.$t("iziToastConfigs.position"),
                        rtl: this.$t("iziToastConfigs.dir"),
                        message: err.response.data.message,
                        messageSize: "22",
                    });
                    return;
                });
            // END:: SEND REQUEST
        },
        // END:: SUBMIT FORM

        // START:: CLEAR PAYMENT FORM DATA
        clearPaymentFormData() {
            this.deliveryInfo.paymentType = null;
            this.deliveryInfo.city = null;
            this.deliveryInfo.district = null;
            this.deliveryInfo.street = null;
            this.deliveryInfo.another_phone = null;
            this.deliveryInfo.additional_info = null;
        },
        // END:: CLEAR PAYMENT FORM DATA

        //START:: GET PAYMENTS NUMBER
        getNumbers() {
            this.$axios({
                method: "GET",
                url: "insta_vodafone",
            }).then((res) => {
                this.instaNumber = res.data.data;
            });
        },
        //END:: GET PAYMENTS NUMBER

        //START:: COPY TEXT
        async copyText() {
            try {
                await navigator.clipboard.writeText(
                    this.instaNumber?.insta_pay
                );
                this.$iziToast.success({
                    timeout: 2000,
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                    message: "تم النسخ بنجاح",
                    messageSize: "22",
                });
            } catch (err) {
                this.$iziToast.success({
                    timeout: 2000,
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                    message: err,
                    messageSize: "22",
                });
            }
        },
        //END:: COPY TEXT
    },

    mounted() {
        // START:: FIRE METHODS
        this.getCites();
        this.getNumbers();
        if (
            this.$route.query.success != undefined &&
            this.$route.query.success
        ) {
            this.submitForm();
        } else if (
            this.$route.query.success != undefined &&
            !this.$route.query.success
        ) {
            this.$iziToast.error({
                timeout: 2000,
                position: this.$t("iziToastConfigs.position"),
                rtl: this.$t("iziToastConfigs.dir"),
                message: "عملية الدفع غير ناجحة",
                messageSize: "22",
            });
        }
        // END:: FIRE METHODS
    },
};
</script>
