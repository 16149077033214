<template>
    <v-app>
        <transition name="fadeInUp" mode="out-in">
            <router-view></router-view>
        </transition>
    </v-app>
</template>

<script>
// START:: IMPORTING VUEX HELPERS
import { mapGetters, mapActions } from "vuex";
// START:: IMPORTING VUEX HELPERS

export default {
    name: "App",
    data() {
        return {
            // socket
            userId: "",
        };
    },
    // START:: VUE-META
    // metaInfo: {
    //     meta: [
    //         {
    //             name: "description",
    //             content: "El Mo3alm El Mosa3d App Description Will Go Here",
    //         },
    //     ],
    // },
    // END:: VUE-META

    computed: {
        // START:: VUEX GET APP THEME
        ...mapGetters("AppThemeModule", ["getAppTheme"]),
        // END:: VUEX GET APP THEME

        // // START:: VUEX GET AUTHENTICATED USER DATA
        // ...mapGetters("AuthenticationModule", ["getAuthenticatedUserData"]),
        // // END:: VUEX GET AUTHENTICATED USER DATA

        // // START:: VUEX GET NOTIFICATIONS DATA
        // ...mapGetters("NotificationsModule", ["notificationsData"]),
        // // END:: VUEX GET NOTIFICATIONS DATA
    },

    methods: {
        // START:: VUEX  APP LANGUAGE ACTIONS
        ...mapActions("AppLangModule", ["handelAppDefaultLanguage"]),
        // END:: VUEX APP LANGUAGE ACTIONS

        // START:: VUEX  APP THEME ACTIONS
        ...mapActions("AppThemeModule", ["handelAppDefaultTheme"]),
        // END:: VUEX APP THEME ACTIONS
    },

    created() {
        // START:: SET APP DEFAULT LANGUAGE
        this.handelAppDefaultLanguage();
        // END:: SET APP DEFAULT LANGUAGE

        // START:: SET APP DEFAULT THEME
        this.handelAppDefaultTheme();
        // END:: SET APP DEFAULT THEME
    },
};
</script>
