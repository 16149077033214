var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isLoading)?_c('MainLoader'):_c('div',{staticClass:"course_details_wrapper fadeIn"},[_c('ImageHeader',[[_c('div',{staticClass:"header_container"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"header_title_wrapper"},[_c('h1',[_vm._v(" "+_vm._s(_vm.courseDetails?.subject_name ?.subject_name)+" ")])])])])]],2),_c('div',{staticClass:"corses_details_content_wrapper"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6 my-3 order-2 order-md-1"},[_c('CourseStaticInfoAndAttachments',{attrs:{"description":_vm.courseDetails.desc,"goals":_vm.courseDetails.objectives,"enrichments":_vm.courseDetails.enriching,"attachments":_vm.$route.name == 'course_details_of_teacher'
                                    ? _vm.courseDetails.dash_attachments
                                    : _vm.courseDetails.attachments}})],1),_c('div',{staticClass:"col-lg-6 my-3 order-1 order-md-2"},[_c('div',{staticClass:"course_details_column"},[_c('CourseDetailsAndActionsButtons',{attrs:{"courseId":_vm.courseDetails.id,"subjectName":_vm.courseDetails?.subject_name
                                        ?.subject_name,"subjectDescription":_vm.courseDetails.desc,"acadimicYear":_vm.courseDetails.academic_year,"duration":_vm.courseDetails.diff_of_time,"courseType":_vm.courseType,"routeName":_vm.routeName,"startDate":_vm.courseDetails.start_date,"courseRate":_vm.courseDetails.avg_rate,"coursePrice":_vm.courseDetails.price,"coursePriceAfterDiscount":_vm.courseDetails.price_after_discount,"courseCurrency":_vm.courseDetails.currency,"courseViewType":_vm.courseViewType,"courseIsFavorite":_vm.courseDetails.is_fav,"courseIsSubscribed":_vm.courseDetails.is_subscribe,"lessons":_vm.courseDetails}}),_c('CourseLessonsLinks',{attrs:{"viewType":_vm.courseViewType,"courseType":_vm.courseType,"routeName":_vm.routeName,"lessonsList":_vm.courseDetails},on:{"getDetailsAfterEdit":_vm.getCourseDetails}})],1),(
                                _vm.routeName == 'student_course_details' ||
                                _vm.routeName == 'parent_course_details'
                            )?_c('CourseInstructor',{attrs:{"instructor":_vm.courseDetails.teacher}}):_vm._e(),(_vm.routeName == 'teacher_my_course')?_c('CourseRegisteredStudents',{attrs:{"courseRegisteredStudents":_vm.courseDetails.subscription_data}}):_vm._e()],1),_c('div',{staticClass:"col-12 order-3"},[_c('CourseRates',{attrs:{"courseRates":_vm.courseRates}})],1),(_vm.routeName != 'teacher_my_course')?_c('div',{staticClass:"col-12 order-4"},[_c('SetCourseRate')],1):_vm._e()])])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }