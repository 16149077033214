<template>
    <div class="course_ratings_column">
        <h3 class="title">
            {{ $t("TITLES.ratings") }}
        </h3>

        <!-- START:: EMPTY MESSAGE -->
        <EmptyRatesMessage v-if="courseRates?.length == 0" />
        <!-- END:: EMPTY MESSAGE -->

        <!-- START:: RATING CARD -->
        <template v-else>
            <div
                v-for="comment in courseRates"
                :key="comment.id"
                class="rating_card"
            >
                <div class="image_wrapper">
                    <img
                        :src="comment.user_Data.image"
                        alt="Image"
                        width="80"
                        height="80"
                    />
                </div>

                <div class="wrapper">
                    <!-- START:: NAME AND RATINGS -->
                    <h5 class="name_and_rate">
                        <span class="name">
                            {{ comment.user_Data.fullname }}
                        </span>
                        <span class="rate">
                            <RatingStars dir="ltr" :rate="comment.rate" />
                        </span>
                    </h5>
                    <!-- END:: NAME AND RATINGS -->

                    <!-- START:: POSITION -->
                    <h5 class="position">
                        <span v-if="comment.user_Data.user_type == 'teacher'"
                            >معلم</span
                        >
                        <span v-if="comment.user_Data.user_type == 'student'"
                            >طالب</span
                        >
                        <span v-if="comment.user_Data.user_type == 'parent'"
                            >ولي أمر</span
                        >
                    </h5>
                    <!-- END:: POSITION -->

                    <!-- START:: COMMENT -->
                    <h5 class="comment">
                        {{ comment.content }}
                    </h5>
                    <!-- END:: COMMENT -->
                </div>
            </div>
        </template>
        <!-- END: RATING CARD -->
    </div>
</template>

<script>
// START:: IMPORTING RATING STARS
import RatingStars from "../../components/ui/RatingStars.vue";
// END:: IMPORTING RATING STARS

// START:: IMPORTING EMPTY MESSAGES
import EmptyRatesMessage from "../ui/emptyMessages/EmptyRatesMessage.vue";
// END:: IMPORTING EMPTY MESSAGES

export default {
    name: "CourseRates",

    components: {
        RatingStars,
        EmptyRatesMessage,
    },

    props: ["courseRates"],
};
</script>
