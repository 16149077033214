<template>
    <div class="checkout_wrapper">
        <!-- START:: BREADCRUMB -->
        <Breadcrumb>
            <template v-slot:page_title> {{ $t("TITLES.cart") }} </template>
            <template v-slot:breadcrumb_current_page>
                {{ $t("TITLES.cart") }}
            </template>
        </Breadcrumb>
        <!-- END:: BREADCRUMB -->

        <!-- START:: CHECKOUT CONTENT -->
        <div class="checkout_content_wrapper">
            <div class="container">
                <div class="row">
                    <!-- START:: CHECKOUT FORMS ROUTER VIEW-->
                    <div class="col-lg-6 my-3 order-2 order-md-1">
                        <router-view
                            :cartData="getShoppingCartItems"
                        ></router-view>
                    </div>
                    <!-- END:: CHECKOUT FORMS ROUTER VIEW-->

                    <!-- START:: CHECKOUT ITEMS -->
                    <div class="col-lg-6 my-3 order-1 order-md-2">
                        <!-- START:: ITEMS -->
                        <div class="checkout_items_wrapper">
                            <!-- START:: ITEM CARD -->
                            <div
                                v-for="item in getShoppingCartItems.data"
                                :key="item.id"
                            >
                                <div class="cart_item_card_wrapper">
                                    <div class="item_details">
                                        <!-- START:: ITEM IMAGE -->
                                        <div class="image_wrapper">
                                            <img
                                                :src="
                                                    item.product.product_image
                                                "
                                                :alt="item.product.name"
                                                width="120"
                                                height="120"
                                            />
                                        </div>
                                        <!-- END:: ITEM IMAGE -->

                                        <div class="wrapper">
                                            <!-- START:: ITEM NAME AND QUANTITY -->
                                            <h3 class="name_and_quantity">
                                                <span class="name">
                                                    {{ item.product.name }}
                                                </span>
                                                <span class="quantity">
                                                    {{ item.count }} X
                                                </span>
                                            </h3>
                                            <!-- END:: ITEM NAME AND QUANTITY -->

                                            <!-- START:: ITEM PRICE -->
                                            <h3 class="price">
                                                {{ item.total }}
                                                {{ item.product.currency }}
                                            </h3>
                                            <!-- END:: ITEM PRICE -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- END:: ITEM CARD -->
                        </div>
                        <!-- END:: ITEMS -->

                        <!-- START:: SHOPPING CART FINANCIAL -->
                        <div class="shopping_cart_finance">
                            <!-- START:: CART FINANCE LINE -->
                            <div class="cart_finance_line">
                                <h3>
                                    <span class="title">
                                        {{ $t("TITLES.total") }}
                                    </span>
                                    <span class="price">
                                        {{
                                            getShoppingCartItems.price
                                                .total_with_vat
                                        }}جنيه مصري
                                    </span>
                                </h3>
                            </div>
                            <!-- END:: CART FINANCE LINE -->
                        </div>
                        <!-- END:: SHOPPING CART FINANCIAL -->
                    </div>
                    <!-- END:: CHECKOUT ITEMS -->
                </div>
            </div>
        </div>
        <!-- END:: CHECKOUT CONTENT -->
    </div>
</template>

<script>
// START:: IMPORTING VUEX HELPERS
import { mapGetters } from "vuex";
// END:: IMPORTING VUEX HELPERS

// START:: IMPORTING BREADCRUMB
import Breadcrumb from "../../components/structure/TheBreadcrumb.vue";
// END:: IMPORTING BREADCRUMB

export default {
    name: "Checkout",

    components: {
        Breadcrumb,
    },

    computed: {
        // START:: VUEX GET SHOPPING CART DATA
        ...mapGetters("StoreAndWishlistModule", ["getShoppingCartItems"]),
        // END:: VUEX GET SHOPPING CART DATA
    },
};
</script>
