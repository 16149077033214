<template>
    <div class="course_register_payment_form_wrapper">
        <!-- START:: FORM TITLE -->
        <h3 class="form_title">{{ $t("TITLES.register_course") }}</h3>
        <h3 class="form_subtitle">
            {{ $t("TITLES.enter_data_to_register_course") }}
        </h3>
        <!-- END:: FORM TITLE -->

        <!-- START:: FORM WRAPPER -->
        <form class="mt-5" @submit.prevent="validateFormInputs">
            <div class="input_wrapper imaged_radio my-3">
                <div class="form-check">
                    <div class="check_wrapper">
                        <input
                            id="visa"
                            class="form-check-input"
                            type="radio"
                            name="payment_card_select"
                            value="visa"
                            v-model="courseRegisterData.cardType"
                        />
                        <label class="form-check-label" for="visa">
                            <img
                                src="../../assets/media/payment/visa.svg"
                                alt="Visa Logo"
                                width="90"
                                height="60"
                            />
                        </label>
                    </div>

                    <div class="check_wrapper">
                        <input
                            id="instapay"
                            class="form-check-input"
                            type="radio"
                            name="payment_card_select"
                            value="instapay"
                            v-model="courseRegisterData.cardType"
                        />

                        <label class="form-check-label" for="instapay">
                            <img
                                src="../../assets/media/icons/instapay.png"
                                alt="MasterCard Logo"
                                width="90"
                                height="60"
                            />
                        </label>
                    </div>
                </div>
            </div>

            <!--Instapay-->
            <div class="instapay">
                <h4 class="radio_title">
                    {{ $t("TITLES.instapay") }}
                </h4>
                <div class="insta_media">
                    <!--Logo-->
                    <div class="insta_logo">
                        <div class="image">
                            <img src="../../assets/media/icons/instapay.png" />
                        </div>
                        <div class="desc" v-if="instaNumber">
                            <h4>
                                {{ $t("TITLES.auccount_num") }} (مصرف ابوظبي
                                الاسلامي)
                            </h4>
                            <span>{{ instaNumber.insta_pay }}</span>
                        </div>
                    </div>
                    <!--Icon Copy-->
                    <button type="button" @click="copyText" class="copy_btn">
                        <i class="fas fa-copy"></i>
                    </button>
                </div>
            </div>
            <!--Instapay-->

            <!-- START:: QUALIFICATIONS INPUT -->
            <!-- <div class="input_wrapper my-3">
                <input
                    type="text"
                    class="form-control"
                    :placeholder="$t('PLACEHOLDERS.qualifications')"
                    v-model.trim="courseRegisterData.qualifications"
                />
            </div> -->
            <!-- END:: QUALIFICATIONS INPUT -->

            <!-- START:: CAREER STATUS INPUT -->
            <!-- <div class="input_wrapper select_wrapper my-3">
                <input
                    type="text"
                    class="form-control"
                    :placeholder="$t('PLACEHOLDERS.career_status')"
                    v-model.trim="courseRegisterData.careerStatus"
                />
            </div> -->
            <!-- END:: CAREER STATUS INPUT -->

            <!--Select Lessons-->
            <!-- <div
                class="col-lg-12"
                v-if="
                    listens.subject_kind == 'online' &&
                    ($route.name == 'course_details_of_teacher' ||
                        $route.name == 'student_course_details') &&
                    !courseIsSubscribed
                "
            > -->
            <!-- START:: SUBJECT UPLOADED INPUT -->
            <div class="col-12 my-3">
                <div class="single_image_input_wrapper">
                    <div class="wrapper">
                        <label for="user_avatar">
                            <!-- <i class="fal fa-camera-alt"></i> -->
                            صورة التحويل
                        </label>
                        <img
                            class="user_avatar"
                            :src="courseRegisterData.image.bath"
                            alt="User Avatar"
                            width="150"
                            height="150"
                        />
                        <input
                            type="file"
                            accept="image/png, image/jpg, image/jpeg"
                            class="form-control"
                            id="user_avatar"
                            :placeholder="$t('PLACEHOLDERS.transfer_image')"
                            @change="selectUploadedImage"
                        />
                    </div>
                </div>
            </div>
            <!-- END:: SUBJECT UPLOADED INPUT -->
            <div class="input_wrapper select_wrapper my-3">
                <input
                    type="number"
                    class="form-control"
                    :placeholder="$t('PLACEHOLDERS.transfer_from_num')"
                    v-model.trim="courseRegisterData.transfer_from_num"
                />
            </div>
            <div class="col-lg-12">
                <div class="input_wrapper select_wrapper">
                    <multiselect
                        v-model="courseRegisterData.lessonsData"
                        :open-direction="'bottom'"
                        track-by="name"
                        label="name"
                        :placeholder="$t('PLACEHOLDERS.lessons')"
                        :options="listens.listens.filter((x) => !x.is_paid)"
                        :searchable="true"
                        :allow-empty="true"
                        :show-labels="false"
                        multiple
                    >
                    </multiselect>
                </div>
            </div>
            <h4 class="radio_title" v-if="totalPrice > 0">
                السعر الاجمالي : {{ totalPrice }} جنيه مصري
            </h4>
            <!--Select Lessons-->

            <!-- START:: RADIO INPUTS -->
            <div class="input_wrapper radio my-3">
                <h4 class="radio_title">
                    {{ $t("TITLES.course_register_question") }}
                </h4>

                <div class="form-check">
                    <label class="form-check-label">
                        <div class="text-check">
                            <input
                                class="form-check-input"
                                type="radio"
                                name="course_register_question"
                                value="1"
                                v-model="courseRegisterData.registerQuestion"
                            />
                            {{ $t("PLACEHOLDERS.yes") }}
                        </div>
                    </label>

                    <label class="form-check-label">
                        <div class="text-check">
                            <input
                                class="form-check-input"
                                type="radio"
                                name="course_register_question"
                                value="0"
                                v-model="courseRegisterData.registerQuestion"
                            />
                            {{ $t("PLACEHOLDERS.no") }}
                        </div>
                    </label>
                </div>
            </div>
            <!-- END:: RADIO INPUTS -->

            <!-- START:: SUBMIT BUTTON WRAPPER -->
            <div class="btn_wrapper my-3">
                <button class="w-100 mt-0">
                    {{ $t("BUTTONS.submit") }}
                    <span class="btn_loader" v-if="isWaitingRequest"></span>
                </button>
            </div>
            <!-- END:: SUBMIT BUTTON WRAPPER -->
        </form>
        <!-- END:: FORM WRAPPER -->
    </div>
</template>

<script>
import image_path from "../../assets/media/images/upload_image.png";
export default {
    name: "CourseRegisterPayment",

    props: ["listens"],

    data() {
        return {
            // START:: LOADER HANDLING DATA
            isWaitingRequest: false,
            // END:: LOADER HANDLING DATA

            // START:: USER TYPE
            userType: localStorage.getItem("elmo3lm_elmosa3d_user_type"),
            // END:: USER TYPE

            // START:: COURSE REGISTER DATA
            courseRegisterData: {
                image: {
                    bath: image_path,
                    file: null,
                },
                transfer_from_num: null,
                qualifications: null,
                careerStatus: null,
                registerQuestion: null,
                subject_subscription_id: null,
                cardType: null,
                lessonsData: [],
            },
            // END:: COURSE REGISTER DATA

            //  START:: INSTA NUMBER
            instaNumber: null,
            copy: false,
            //  END:: INSTA NUMBER
        };
    },

    methods: {
        // START:: VALIDATE FORM INPUTS
        validateFormInputs() {
            this.isWaitingRequest = true;
            if (!this.courseRegisterData.cardType) {
                this.isWaitingRequest = false;
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.cardType"),
                    messageSize: "22",
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            } else if (!this.courseRegisterData.image.file) {
                this.isWaitingRequest = false;
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.transfer_image"),
                    messageSize: "22",
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            } else if (!this.courseRegisterData.transfer_from_num) {
                this.isWaitingRequest = false;
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.transfer_from_num"),
                    messageSize: "22",
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            }
            // else if (!this.courseRegisterData.qualifications) {
            //     this.isWaitingRequest = false;
            //     this.$iziToast.error({
            //         timeout: 2000,
            //         message: this.$t("VALIDATION.qualifications"),
            //         messageSize: "22",
            //         position: this.$t("iziToastConfigs.position"),
            //         rtl: this.$t("iziToastConfigs.dir"),
            //     });
            //     return;
            // } else if (!this.courseRegisterData.careerStatus) {
            //     this.isWaitingRequest = false;
            //     this.$iziToast.error({
            //         timeout: 2000,
            //         message: this.$t("VALIDATION.careerStatus"),
            //         messageSize: "22",
            //         position: this.$t("iziToastConfigs.position"),
            //         rtl: this.$t("iziToastConfigs.dir"),
            //     });
            //     return;
            // }
            else if (this.courseRegisterData.lessonsData.length == 0) {
                this.isWaitingRequest = false;
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.lessons"),
                    messageSize: "22",
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            } else {
                if (this.courseRegisterData.cardType == "visa") {
                    this.submitForm();
                } else if (this.courseRegisterData.cardType == "instapay") {
                    console.log("Dd");

                    this.payInsta();
                }
            }
        },

        payMob(id) {
            // START:: SEND REQUEST
            const theData = new FormData();
            theData.append("price", this.totalPrice);
            theData.append(
                "redirect_url",
                `${window.location.origin}/course-payment/${
                    this.$route.params.id
                }?lessons=${JSON.stringify(
                    this.courseRegisterData?.lessonsData.map((x) => x.id)
                )} &&cardType=${
                    this.courseRegisterData.cardType
                } &&subject_subscription_id=${id}`
            );
            // START:: APPEND CONTACT DATA
            // START:: SEND REQUEST
            this.$axios
                .post("website_paymob", theData, {
                    headers: {
                        Authorization:
                            "Bearer " +
                            localStorage.getItem("elmo3lm_elmosa3d_user_token"),
                        "Accept-language": localStorage.getItem(
                            "elmo3lm_elmosa3d_app_lang"
                        ),
                        "cache-control": "no-cache",
                        Accept: "application/json",
                    },
                })
                .then((res) => {
                    // this.iframe_id = res.data.extra_data.online_iframe;
                    // this.token = res.data.data.token;
                    // this.modalApperanceData = true;
                    window.location.href = res.data.data;
                })
                .catch((err) => {
                    this.$iziToast.error({
                        timeout: 2000,
                        message: err.response.data.message,
                        messageSize: "22",
                        position: this.$t("iziToastConfigs.position"),
                        rtl: this.$t("iziToastConfigs.dir"),
                    });
                    return;
                });
            // END:: FAV CLICK
        },

        // START:: SELECT UPLOADED IMAGE
        selectUploadedImage(e) {
            this.courseRegisterData.image.bath = URL.createObjectURL(
                e.target.files[0]
            );
            this.courseRegisterData.image.file = e.target.files[0];
        },
        // END:: SELECT UPLOADED IMAGE

        // END:: VALIDATE FORM INPUTS
        submitForm() {
            const theData = new FormData();
            if (this.userType == "teacher") {
                theData.append(
                    "qualifications",
                    this.courseRegisterData.qualifications
                );
                theData.append(
                    "professional_status",
                    this.courseRegisterData.careerStatus
                );
            }
            theData.append(
                "attending_online_course_before",
                this.courseRegisterData.registerQuestion
            );
            theData.append("subject_id", this.$route.params.id);

            if (this.userType == "teacher") {
                // START:: SEND REQUEST
                this.$axios
                    .post("teacher/category/subject/payment", theData, {
                        headers: {
                            Authorization:
                                "Bearer " +
                                localStorage.getItem(
                                    "elmo3lm_elmosa3d_user_token"
                                ),
                            "Accept-language": localStorage.getItem(
                                "elmo3lm_elmosa3d_app_lang"
                            ),
                            "cache-control": "no-cache",
                            Accept: "application/json",
                        },
                    })
                    .then((res) => {
                        this.courseRegisterData.subject_subscription_id =
                            res.data.data.id;
                        this.paymentMethods();

                        return;
                    })
                    .catch((err) => {
                        this.isWaitingRequest = false;
                        this.$iziToast.error({
                            timeout: 2000,
                            position: this.$t("iziToastConfigs.position"),
                            rtl: this.$t("iziToastConfigs.dir"),
                            message: err.response.data,
                            messageSize: "22",
                        });
                        return;
                    });
                // END:: SEND REQUEST
            } else if (this.userType == "student") {
                // START:: SEND REQUEST
                this.$axios
                    .post("student/subject/payment", theData, {
                        headers: {
                            Authorization:
                                "Bearer " +
                                localStorage.getItem(
                                    "elmo3lm_elmosa3d_user_token"
                                ),
                            "Accept-language": localStorage.getItem(
                                "elmo3lm_elmosa3d_app_lang"
                            ),
                            "cache-control": "no-cache",
                            Accept: "application/json",
                        },
                    })
                    .then((res) => {
                        this.courseRegisterData.subject_subscription_id =
                            res.data.data.id;
                        // this.paymentMethods();
                        this.payMob(res.data.data.id);

                        return;
                    })
                    .catch((err) => {
                        this.isWaitingRequest = false;
                        this.$iziToast.error({
                            timeout: 2000,
                            position: this.$t("iziToastConfigs.position"),
                            rtl: this.$t("iziToastConfigs.dir"),
                            message: err.response.data.message,
                            messageSize: "22",
                        });
                        return;
                    });
                // END:: SEND REQUEST
            }
        },
        // END:: SUBMIT FORM
        paymentMethods() {
            const theData = new FormData();
            theData.append("transactionId", this.$route.query?.id);
            theData.append("pay_type", this.$route.query?.cardType);
            theData.append(
                "subject_subscription_id",
                this.$route.query.subject_subscription_id
            );
            JSON.parse(this.$route.query.lessons).forEach((element, index) => {
                theData.append(`lessons[${index}][id]`, element);
            });
            // START:: SEND REQUEST
            this.$axios
                .post("student/subject/complete-payment", theData, {
                    headers: {
                        Authorization:
                            "Bearer " +
                            localStorage.getItem("elmo3lm_elmosa3d_user_token"),
                        "Accept-language": localStorage.getItem(
                            "elmo3lm_elmosa3d_app_lang"
                        ),
                        "cache-control": "no-cache",
                        Accept: "application/json",
                    },
                })
                .then(() => {
                    this.$router.replace("/my-courses");
                    this.$iziToast.success({
                        timeout: 2000,
                        message: this.$t("VALIDATION.paymentSuccess"),
                        messageSize: "22",
                        position: this.$t("iziToastConfigs.position"),
                        rtl: this.$t("iziToastConfigs.dir"),
                    });
                    this.isWaitingRequest = false;
                    this.$router.replace("/my-courses");
                    return;
                })
                .catch(() => {
                    this.isWaitingRequest = false;
                    this.$iziToast.error({
                        timeout: 2000,
                        position: this.$t("iziToastConfigs.position"),
                        rtl: this.$t("iziToastConfigs.dir"),
                        message: this.$t("VALIDATION.login_failed"),
                        messageSize: "22",
                    });
                    return;
                });
            // END:: SEND REQUEST
        },

        //START:: PAY INSTAPAY
        payInsta() {
            const theData = new FormData();
            theData.append("subject_id", this.$route.params.id);
            if (this.courseRegisterData.image.file) {
                theData.append(
                    "transfer_image",
                    this.courseRegisterData.image.file
                );
            }
            if (this.courseRegisterData.transfer_from_num) {
                theData.append(
                    "transfer_from_num",
                    this.courseRegisterData.transfer_from_num
                );
            }
            this.courseRegisterData?.lessonsData.forEach((element, index) => {
                theData.append(`lessons[${index}][id]`, element.id);
            });
            this.$axios({
                method: "POST",
                url: "student/subject/pay_insta",
                data: theData,
            })
                .then(() => {
                    this.isWaitingRequest = false;
                    this.$iziToast.success({
                        timeout: 2000,
                        position: this.$t("iziToastConfigs.position"),
                        rtl: this.$t("iziToastConfigs.dir"),
                        message: "تم الارسال بنجاح",
                        messageSize: "22",
                    });
                    // this.$router.replace(
                    //     `student/course-details/online/${this.$route.params.id}`
                    // );
                    return;
                })
                .catch((err) => {
                    this.isWaitingRequest = false;
                    this.$iziToast.error({
                        timeout: 2000,
                        position: this.$t("iziToastConfigs.position"),
                        rtl: this.$t("iziToastConfigs.dir"),
                        message: err.response.data.message,
                        messageSize: "22",
                    });
                    return;
                });
        },
        //END:: PAY INSTAPAY

        //START:: GET PAYMENTS NUMBER
        getNumbers() {
            this.$axios({
                method: "GET",
                url: "insta_vodafone",
            }).then((res) => {
                this.instaNumber = res.data.data;
            });
        },
        //END:: GET PAYMENTS NUMBER

        //START:: COPY TEXT
        async copyText() {
            try {
                await navigator.clipboard.writeText(
                    this.instaNumber?.insta_pay
                );
                this.$iziToast.success({
                    timeout: 2000,
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                    message: "تم النسخ بنجاح",
                    messageSize: "22",
                });
            } catch (err) {
                this.$iziToast.success({
                    timeout: 2000,
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                    message: err,
                    messageSize: "22",
                });
            }
        },
        //END:: COPY TEXT

        //START::PAYMENT FAWRY
        fawryInit() {
            try {
                this.$axios({
                    method: "POST",
                    url: "https://atfawry.fawrystaging.com/fawrypay-api/api/payments/init",
                });
            } catch (err) {
                console.log(err);
            }
        },
        //END::PAYMENT FAWRY
    },
    computed: {
        // START:: GET LESSONS DATA
        totalPrice() {
            return this.courseRegisterData.lessonsData?.reduce(
                (sum, item) => sum + item.price,
                0
            );
        },
        // END:: GET LESSONS DATA
    },

    mounted() {
        console.log(this.$route.query.success);

        if (
            this.$route.query.success != undefined &&
            this.$route.query.success
        ) {
            this.paymentMethods();
        } else if (
            this.$route.query.success != undefined &&
            !this.$route.query.success
        ) {
            this.$iziToast.error({
                timeout: 2000,
                position: this.$t("iziToastConfigs.position"),
                rtl: this.$t("iziToastConfigs.dir"),
                message: "عملية الدفع غير ناجحة",
                messageSize: "22",
            });
        }
        this.getNumbers();
        this.fawryInit();
    },
};
</script>
