<template :key="$route.path">
    <div class="courses_cats">
        <!-- START:: BREADCRUMB -->
        <Breadcrumb>
            <template v-slot:page_title>
                {{ $t("TITLES.added_subjects") }}
            </template>
            <template v-slot:breadcrumb_current_page>
                {{ $t("TITLES.added_subjects") }}
            </template>
        </Breadcrumb>
        <!-- END:: BREADCRUMB -->
        <!-- START:: MAIN LOADER -->
        <MainLoader v-if="isLoading" />
        <!-- END:: MAIN LOADER -->
        <div class="courses_cats_content_wrapper fadeIn" v-else>
            <div class="container">
                <!-- START:: TABS -->
                <Tabs :tabsContent="tabsContent">
                    <!-- START:: REGISTERED COURSES TAB -->
                    <template #registered_courses>
                        <!-- START:: EMPTY COURSES MESSAGE -->
                        <EmptyCoursesMessage
                            v-if="registeredCourses?.length == 0"
                        />
                        <!-- END:: EMPTY COURSES MESSAGE -->

                        <!-- START:: COURSES -->
                        <div class="registered_courses_wrapper" v-else>
                            <div class="row">
                                <!-- START:: REGISTERED COURSE CARD -->
                                <div
                                    v-for="course in registeredCourses"
                                    :key="course.id"
                                    class="col-lg-3 my-3"
                                >
                                    <!-- START:: COURSE CARD -->
                                    <router-link
                                        :to="`/teacher/my-course/record/${course.id}`"
                                        :courseId="course.id"
                                    >
                                        <ImagedCard>
                                            <template v-slot:card_image>
                                                <img
                                                    :src="course.image"
                                                    alt="Course Category Image"
                                                />
                                            </template>

                                            <template v-slot:card_text>
                                                <div class="wrapper">
                                                    <p class="course_name">
                                                        {{
                                                            course.subject_name
                                                                .subject_name
                                                        }}
                                                    </p>
                                                    <p class="students_count">
                                                        {{
                                                            course.academic_year
                                                                .name
                                                        }}
                                                    </p>
                                                    <p class="students_count">
                                                        {{
                                                            course.number_of_subscription
                                                        }}
                                                        طالب مسجل
                                                    </p>
                                                </div>
                                            </template>
                                        </ImagedCard>
                                    </router-link>
                                    <!-- END:: COURSE CARD -->
                                </div>
                                <!-- END:: REGISTERED COURSE CARD -->
                            </div>
                        </div>
                        <!-- END:: COURSES -->
                    </template>
                    <!-- END:: REGISTERED COURSES TAB -->

                    <!-- START:: ONLINE COURSES TAB -->
                    <template #online_courses>
                        <!-- START:: EMPTY COURSES MESSAGE -->
                        <EmptyCoursesMessage v-if="onlineCourses.length == 0" />
                        <!-- END:: EMPTY COURSES MESSAGE -->

                        <!-- START:: COURSES -->
                        <div class="registered_courses_wrapper" v-else>
                            <div class="row">
                                <!-- START:: ONLINE COURSE CARD -->
                                <div
                                    v-for="course in onlineCourses"
                                    :key="course.id"
                                    class="col-lg-3 my-3"
                                >
                                    <!-- START:: COURSE CARD -->
                                    <router-link
                                        :to="`/teacher/my-course/online/${course.id}`"
                                    >
                                        <ImagedCard>
                                            <template v-slot:card_image>
                                                <img
                                                    :src="course.image"
                                                    alt="Course Category Image"
                                                />
                                            </template>

                                            <template v-slot:card_text>
                                                <div class="wrapper">
                                                    <p class="course_name">
                                                        {{
                                                            course.subject_name
                                                                .subject_name
                                                        }}
                                                    </p>
                                                    <p class="students_count">
                                                        {{
                                                            course.academic_year
                                                                .name
                                                        }}
                                                        ({{
                                                            course.degree.degree
                                                                .name
                                                        }})
                                                    </p>
                                                    <p class="students_count">
                                                        {{
                                                            course.number_of_subscription
                                                        }}
                                                        طالب مسجل
                                                    </p>
                                                    <p class="students_count">
                                                        {{
                                                            course.status_trans
                                                        }}
                                                    </p>
                                                </div>

                                                <div class="wrapper">
                                                    <p class="price">
                                                        سعر الحصة :
                                                        {{ course.price }}
                                                        ج.م
                                                    </p>
                                                </div>
                                            </template>
                                        </ImagedCard>
                                    </router-link>
                                    <!-- END:: COURSE CARD -->
                                </div>
                                <!-- END:: ONLINE COURSE CARD -->
                            </div>
                        </div>
                        <!-- END:: COURSES -->
                    </template>
                    <!-- END:: ONLINE COURSES TAB -->
                </Tabs>
                <!-- END:: TABS -->
            </div>
        </div>
    </div>
</template>

<script>
// START:: IMPORTING VUEX HELPERS
import { mapGetters } from "vuex";
// END:: IMPORTING VUEX HELPERS

// START:: IMPORTING EMPTY MESSAGES
import EmptyCoursesMessage from "../../components/ui/emptyMessages/EmptyCoursesMessage.vue";
// END:: IMPORTING EMPTY MESSAGES
// START:: IMPORTING BREADCRUMB
import Breadcrumb from "../../components/structure/TheBreadcrumb.vue";
// END:: IMPORTING BREADCRUMB

// START:: IMPORTING TABS
import Tabs from "../../components/structure/Tabs.vue";
// END:: IMPORTING TABS

// START:: IMPORTING UI IMAGED CARD
import ImagedCard from "../../components/ui/ImagedCard.vue";
// END:: IMPORTING UI IMAGED CARD
// START:: IMPORTING LOADERS
import MainLoader from "../../components/ui/loaders/MainLoader.vue";
// END:: IMPORTING LOADERS
export default {
    name: "AddedCourses",
    components: {
        EmptyCoursesMessage,
        Breadcrumb,
        Tabs,
        ImagedCard,
        MainLoader,
    },
    computed: {
        // START:: VUEX GET AUTHENTICATED USER DATA
        ...mapGetters("AuthenticationModule", ["getAuthenticatedUserData"]),
        // END:: VUEX GET AUTHENTICATED USER DATA
    },

    data() {
        return {
            // START:: HANDLING SKELETON LOADER APPERANCE
            isLoading: false,
            // END:: HANDLING SKELETON LOADER APPERANCE

            // START:: TABS CONTENT
            tabsContent: [
                {
                    tab: this.$t("TABS.online_courses"),
                    key_name: "online_courses",
                },
                // {
                //     tab: this.$t("TABS.registered_courses"),
                //     key_name: "registered_courses",
                // }
            ],
            // END:: TABS CONTENT

            // START:: REGISTERED COURSES DATA
            registeredCourses: [],
            // END:: REGISTERED COURSES DATA

            // START:: REGISTERED COURSES DATA
            onlineCourses: [],
            // END:: REGISTERED COURSES DATA
        };
    },

    methods: {
        // START:: GET ADDED COURSES
        getAddedCoursesData() {
            this.isLoading = true;
            // ============ START:: RECORD CORSES
            this.$axios("teacher/subject?subject_kind=record", {
                headers: {
                    Authorization:
                        "Bearer " +
                        localStorage.getItem("elmo3lm_elmosa3d_user_token"),
                    "Accept-language": localStorage.getItem(
                        "elmo3lm_elmosa3d_app_lang"
                    ),
                    "cache-control": "no-cache",
                    Accept: "application/json",
                },
            }).then((res) => {
                this.registeredCourses = res.data.data;
                this.isLoading = false;
            });
            // ============ END:: RECORD CORSES

            // ============ START:: ONLINE CORSES
            this.$axios("teacher/subject?subject_kind=online", {
                headers: {
                    Authorization:
                        "Bearer " +
                        localStorage.getItem("elmo3lm_elmosa3d_user_token"),
                    "Accept-language": localStorage.getItem(
                        "elmo3lm_elmosa3d_app_lang"
                    ),
                    "cache-control": "no-cache",
                    Accept: "application/json",
                },
            }).then((res) => {
                this.onlineCourses = res.data.data;
                this.isLoading = false;
            });
            // ============ END:: ONLINE CORSES
        },
        // END:: GET ADDED COURSES
    },

    mounted() {
        // START:: FIRE METHODS
        this.getAddedCoursesData();
        // END:: FIRE METHODS
    },
};
</script>
