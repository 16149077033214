export default {
    // START:: SET AUTHENTICATED USER DATA
    setAuthenticatedUserData(state, payload) {
        state.userType = payload.type;
        state.userToken = payload.token;
        state.userTokenBeforeLogin = payload.token_before;
        state.userAvatar = payload.avatar;
        localStorage.setItem("elmo3lm_elmosa3d_user_type", payload.type);
        localStorage.setItem("elmo3lm_elmosa3d_user_token", payload.token);
        localStorage.setItem(
            "elmo3lm_elmosa3d_user_token_before_login",
            payload.token_before
        );
        localStorage.setItem("elmo3lm_elmosa3d_user_avatar", payload.avatar);
    },
    // END:: SET AUTHENTICATED USER DATA
};
