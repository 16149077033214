<template>
    <div>
        <!-- START:: MAIN LOADER -->
        <MainLoader v-if="isLoading" />
        <!-- END:: MAIN LOADER -->

        <!-- START:: PAGE CONTENT -->
        <div class="about_us_page_wrapper fadeIn" v-else>
            <!-- START:: BREADCRUMB -->
            <Breadcrumb>
                <template v-slot:page_title>
                    {{ $t("TITLES.about_us") }}
                </template>
                <template v-slot:breadcrumb_current_page>
                    {{ $t("TITLES.about_us") }}
                </template>
            </Breadcrumb>
            <!-- END:: BREADCRUMB -->

            <!-- START:: ABOUT US PAGE CONTENT -->
            <AboutUs :aboutUsData="homeData?.about" />
            <!-- END:: ABOUT US PAGE CONTENT -->

            <!-- START:: CONTACT SECTION -->
            <ContactUs :contactUsData="homeData?.contacts" />
            <!-- END:: CONTACT SECTION -->
        </div>
        <!-- END:: PAGE CONTENT -->
    </div>
</template>

<script>
// START:: IMPORTING BREADCRUMB
import Breadcrumb from "../../components/structure/TheBreadcrumb.vue";
// END:: IMPORTING BREADCRUMB

// START:: IMPORTING LOADERS
import MainLoader from "../../components/ui/loaders/MainLoader.vue";
// END:: IMPORTING LOADERS

import AboutUs from "../../components/general/AboutUs.vue";
import ContactUs from "../../components/general/ContactUs.vue";

import logoImg from "@/assets/media/logo/logo.svg";

// import { updateMeta } from "@/plugins/updateMeta";

export default {
    name: "AboutUsPage",
    metaInfo() {
        return {
            title: this.seoData ? this.seoData.title : "منصة فايرا التعليمية",
            meta: [
                {
                    name: "description",
                    content: this.seoData
                        ? this.seoData.description
                        : "منصة فايرا التعليمية",
                },
                {
                    property: "og:title",
                    content: this.seoData
                        ? this.seoData.title
                        : "منصة فايرا التعليمية",
                },
                {
                    property: "author",
                    content: this.seoData
                        ? this.seoData.title
                        : "منصة فايرا التعليمية",
                },
                {
                    property: "keywords",
                    content: this.seoData
                        ? this.seoData.keywords
                        : "منصة فايرا التعليمية",
                },
                {
                    property: "image",
                    content: this.seoData ? this.seoData.image : logoImg,
                },
                {
                    property: "og:image",
                    content: this.seoData ? this.seoData.image : logoImg,
                },
                {
                    property: "robots",
                    content: "index,follow",
                },
            ],
        };
    },
    // computed: {
    //     metaInfo() {
    //         return {
    //             title: this.seoData?.title || "منصة فايرا التعليمية",
    //             meta: [
    //                 {
    //                     vmid: "author",
    //                     name: "author",
    //                     content: "Egypt Faira",
    //                 },
    //                 {
    //                     vmid: "description",
    //                     name: "description",
    //                     content:
    //                         this.seoData?.description || "منصة فايرا التعليمية",
    //                 },
    //                 {
    //                     vmid: "keywords",
    //                     name: "keywords",
    //                     content:
    //                         this.seoData?.keywords ||
    //                         "مدارس , طلاب, معلمين , دروس",
    //                 },
    //                 {
    //                     vmid: "image",
    //                     name: "image",
    //                     content: logoImg,
    //                 },
    //                 {
    //                     vmid: "og:image",
    //                     name: "og:image",
    //                     content: logoImg,
    //                 },
    //             ],
    //         };
    //     },
    // },

    components: {
        MainLoader,
        Breadcrumb,
        AboutUs,
        ContactUs,
    },

    data() {
        return {
            // START:: HANDLING SKELETON LOADER APPERANCE
            isLoading: false,
            // END:: HANDLING SKELETON LOADER APPERANCE

            // START:: HOME DATA
            homeData: null,
            // END:: HOME DATA

            // START:: SEO DATA
            seoData: null,
            // END:: SEO DATA
        };
    },

    methods: {
        // START:: GET HOME PAGE DATA
        getHomeData() {
            // START:: GET TEACHER HOME DATA
            this.isLoading = true;
            var userType = localStorage.getItem("elmo3lm_elmosa3d_user_type");
            if (userType == "teacher") {
                this.$axios
                    .get("teacher/home", {
                        headers: {
                            Authorization:
                                "Bearer " +
                                localStorage.getItem(
                                    "elmo3lm_elmosa3d_user_token"
                                ),
                            "Accept-language": localStorage.getItem(
                                "elmo3lm_elmosa3d_app_lang"
                            ),
                            "cache-control": "no-cache",
                            Accept: "application/json",
                        },
                    })
                    .then((res) => {
                        setTimeout(() => {
                            this.isLoading = false;
                        }, 500);
                        this.homeData = res.data.data;
                        this.seoData = res.data.home_seo_meta;
                        // updateMeta(this.seoData);
                    });
            } else if (userType == "student") {
                this.$axios
                    .get("student/home", {
                        headers: {
                            Authorization:
                                "Bearer " +
                                localStorage.getItem(
                                    "elmo3lm_elmosa3d_user_token"
                                ),
                            "Accept-language": localStorage.getItem(
                                "elmo3lm_elmosa3d_app_lang"
                            ),
                            "cache-control": "no-cache",
                            Accept: "application/json",
                        },
                    })
                    .then((res) => {
                        setTimeout(() => {
                            this.isLoading = false;
                        }, 500);
                        this.homeData = res.data.data;
                        this.seoData = res.data.home_seo_meta;
                        // updateMeta(this.seoData);
                    });
            } else if (userType == "parent") {
                this.$axios
                    .get("parent/home", {
                        headers: {
                            Authorization:
                                "Bearer " +
                                localStorage.getItem(
                                    "elmo3lm_elmosa3d_user_token"
                                ),
                            "Accept-language": localStorage.getItem(
                                "elmo3lm_elmosa3d_app_lang"
                            ),
                            "cache-control": "no-cache",
                            Accept: "application/json",
                        },
                    })
                    .then((res) => {
                        setTimeout(() => {
                            this.isLoading = false;
                        }, 500);
                        this.homeData = res.data.data;
                        this.seoData = res.data.home_seo_meta;
                        // updateMeta(this.seoData);
                    });
            } else {
                this.$axios
                    .get("visitor/home", {
                        headers: {
                            "Accept-language": localStorage.getItem(
                                "elmo3lm_elmosa3d_app_lang"
                            ),
                            "cache-control": "no-cache",
                            Accept: "application/json",
                        },
                    })
                    .then((res) => {
                        setTimeout(() => {
                            this.isLoading = false;
                        }, 500);
                        this.homeData = res.data.data;
                        this.seoData = res.data.home_seo_meta;
                        // updateMeta(this.seoData);
                    });
            }
            // END:: GET TEACHER HOME DATA
        },
        // END:: GET HOME PAGE DATA
    },

    mounted() {
        // START:: FIRE METHODS
        this.getHomeData();
        // END:: FIRE METHODS
    },
};
</script>
