export default {
    // =================== START:: STORE GETTERS ===================
    // START:: STORE ITEMS GETTER
    getStoreItems(state) {
        return {
            setStoreItems: state.storeItems,
            last_page: state.last_page,
            seoData: state.seoData,
        };
    },
    // END:: STORE ITEMS GETTER

    // START:: SHOPPING CART ITEMS GETTER
    getShoppingCartItems(state) {
        return state.shoppingCartItems;
    },
    // END:: SHOPPING CART ITEMS GETTER
    // =================== END:: STORE GETTERS ===================

    // =================== START:: WISHLIST GETTERS ===================
    // START:: WISHLIST ITEMS GETTER
    getWishlistItems(state) {
        return state.favorites;
    },
    // END:: WISHLIST ITEMS GETTER

    // START:: SHOW CASH GETTER
    getShowCash(state) {
        return state.show_cash;
    },
    // END:: SHOW CASH GETTER
    // =================== END:: WISHLIST GETTERS ===================
};
