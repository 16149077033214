<template>
    <div class="my_courses_wrapper">
        <!-- START:: BREADCRUMB -->
        <Breadcrumb>
            <template v-slot:page_title>
                {{ $t("TITLES.my_courses") }}
            </template>
            <template v-slot:breadcrumb_current_page>
                {{ $t("TITLES.my_courses") }}
            </template>
        </Breadcrumb>
        <!-- END:: BREADCRUMB -->

        <div
            class="courses_cats_content_wrapper"
            v-if="getAuthenticatedUserData.type == 'student'"
        >
            <div class="container">
                <!-- START:: TABS -->
                <Tabs :tabsContent="tabsContent" @getItems="getItems">
                    <!-- START:: REGISTERED COURSES TAB -->
                    <template #registered_courses>
                        <!-- START:: MAIN LOADER -->
                        <MainLoader
                            class="custom_loader"
                            v-if="
                                isLoading &&
                                currentTab == 'registered_courses' &&
                                current_page == 1
                            "
                        />
                        <!-- END:: MAIN LOADER -->
                        <div class="fadeIn" v-else>
                            <!-- START:: EMPTY COURSES MESSAGE -->
                            <EmptyCoursesMessage
                                v-if="registeredCourses?.length == 0"
                            />
                            <!-- END:: EMPTY COURSES MESSAGE -->

                            <div class="registered_courses_wrapper" v-else>
                                <div class="row">
                                    <!-- START:: REGISTERED COURSE CARD -->
                                    <div
                                        v-for="course in registeredCourses"
                                        :key="course.id"
                                        class="col-lg-3 my-3 fadeIn"
                                    >
                                        <!-- START:: COURSE CARD -->
                                        <router-link
                                            :to="`/student/course-details/registered/${course.id}`"
                                        >
                                            <ImagedCard>
                                                <template v-slot:card_image>
                                                    <img
                                                        :src="course.image"
                                                        :alt="
                                                            course.subject_name
                                                                .subject_name
                                                        "
                                                    />
                                                    <div class="overlay">
                                                        <div
                                                            class="instructor_info_wrapper"
                                                        >
                                                            <img
                                                                :src="
                                                                    course
                                                                        .teacher
                                                                        .image
                                                                "
                                                                :alt="
                                                                    course
                                                                        .teacher
                                                                        .fullname
                                                                "
                                                                width="100"
                                                                height="100"
                                                            />

                                                            <div class="text">
                                                                <p class="name">
                                                                    {{
                                                                        course
                                                                            .teacher
                                                                            .fullname
                                                                    }}
                                                                </p>
                                                                <p class="rate">
                                                                    <span>
                                                                        <i
                                                                            class="fas fa-star"
                                                                        ></i>
                                                                    </span>
                                                                    <span>
                                                                        {{
                                                                            course.avg_rate
                                                                        }}
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </template>

                                                <template v-slot:card_text>
                                                    <div class="wrapper">
                                                        <p class="course_name">
                                                            {{
                                                                course
                                                                    .subject_name
                                                                    .subject_name
                                                            }}
                                                        </p>
                                                        <p class="duration">
                                                            <span>
                                                                {{
                                                                    course.num_of_hour
                                                                }}
                                                                ساعات
                                                            </span>
                                                            <!-- <span>
                                                            {{course.duration.minutes}}
                                                            دقيقة
                                                        </span> -->
                                                        </p>
                                                    </div>
                                                </template>
                                            </ImagedCard>
                                        </router-link>
                                        <!-- END:: COURSE CARD -->
                                    </div>
                                    <!-- END:: REGISTERED COURSE CARD -->
                                </div>
                            </div>
                            <!-- START:: PAGENATION FOR TEACHER-->
                            <Pagenation
                                :last_page="last_page"
                                :current_page="current_page"
                                @pagenationClick="pagenationClick"
                            ></Pagenation>
                            <!-- END:: PAGENATION FOR TEACHER-->
                        </div>
                    </template>
                    <!-- END:: REGISTERED COURSES TAB -->

                    <!-- START:: ONLINE COURSES TAB -->
                    <template
                        #online_courses
                        v-if="currentTab == 'online_courses'"
                    >
                        <!-- START:: MAIN LOADER -->
                        <MainLoader
                            class="custom_loader"
                            v-if="
                                isLoading &&
                                currentTab == 'online_courses' &&
                                current_page == 1
                            "
                        />
                        <!-- END:: MAIN LOADER -->
                        <div class="fadeIn" v-else>
                            <!-- START:: EMPTY COURSES MESSAGE -->
                            <EmptyCoursesMessage
                                v-if="onlineCourses.length == 0"
                            />
                            <!-- END:: EMPTY COURSES MESSAGE -->

                            <div class="registered_courses_wrapper" v-else>
                                <div class="row">
                                    <!-- START:: ONLINE COURSE CARD -->
                                    <div
                                        v-for="course in onlineCourses"
                                        :key="course.id"
                                        class="col-lg-3 my-3 fadeIn"
                                    >
                                        <!-- START:: COURSE CARD -->
                                        <router-link
                                            :to="`/student/course-details/online/${course.id}`"
                                        >
                                            <ImagedCard>
                                                <template v-slot:card_image>
                                                    <img
                                                        :src="course.image"
                                                        :alt="
                                                            course.subject_name
                                                                .subject_name
                                                        "
                                                    />
                                                    <div class="overlay">
                                                        <div
                                                            class="instructor_info_wrapper"
                                                        >
                                                            <img
                                                                :src="
                                                                    course
                                                                        .teacher
                                                                        .image
                                                                "
                                                                :alt="
                                                                    course
                                                                        .teacher
                                                                        .fullname
                                                                "
                                                                width="100"
                                                                height="100"
                                                            />

                                                            <div class="text">
                                                                <p class="name">
                                                                    {{
                                                                        course
                                                                            .teacher
                                                                            .fullname
                                                                    }}
                                                                </p>
                                                                <p class="rate">
                                                                    <span>
                                                                        <i
                                                                            class="fas fa-star"
                                                                        ></i>
                                                                    </span>
                                                                    <span>
                                                                        {{
                                                                            course.avg_rate
                                                                        }}
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </template>

                                                <template v-slot:card_text>
                                                    <div class="wrapper">
                                                        <p class="course_name">
                                                            {{
                                                                course
                                                                    .subject_name
                                                                    .subject_name
                                                            }}
                                                        </p>
                                                        <p class="start_date">
                                                            <span class="text">
                                                                {{
                                                                    $t(
                                                                        "TITLES.start_date"
                                                                    )
                                                                }}
                                                            </span>
                                                            <span class="date">
                                                                {{
                                                                    course.start_date
                                                                }}
                                                            </span>
                                                        </p>
                                                    </div>

                                                    <div class="wrapper">
                                                        <p class="price">
                                                            {{ course.price }}
                                                            {{
                                                                course.currency
                                                            }}
                                                        </p>
                                                    </div>
                                                </template>
                                            </ImagedCard>
                                        </router-link>
                                        <!-- END:: COURSE CARD -->
                                    </div>
                                    <!-- END:: ONLINE COURSE CARD -->
                                </div>
                            </div>
                            <!-- START:: PAGENATION FOR TEACHER-->
                            <Pagenation
                                :last_page="last_page"
                                :current_page="current_page"
                                @pagenationClick="pagenationClick"
                            ></Pagenation>
                            <!-- END:: PAGENATION FOR TEACHER-->
                        </div>
                    </template>
                    <!-- END:: ONLINE COURSES TAB -->
                </Tabs>
                <!-- END:: TABS -->
            </div>
        </div>

        <div
            class="courses_cats_content_wrapper"
            v-if="getAuthenticatedUserData.type == 'teacher'"
        >
            <div class="container">
                <MainLoader v-if="isLoading" class="custom_loader" />
                <div class="row fadeIn" v-else>
                    <!-- START:: REGISTERED COURSE CARD -->
                    <div
                        v-for="course in teacherSubjects"
                        :key="course.dashboard_subject.id"
                        class="col-lg-3 my-3"
                    >
                        <!-- START:: COURSE CARD -->
                        <router-link
                            :to="`${getAuthenticatedUserData.type}/course-details/${course.dashboard_subject.subject_kind}/${course.dashboard_subject.id}`"
                        >
                            <ImagedCard>
                                <template v-slot:card_image>
                                    <img
                                        :src="course.dashboard_subject.image"
                                        :alt="
                                            course.dashboard_subject
                                                .subject_name
                                        "
                                    />
                                </template>

                                <template v-slot:card_text>
                                    <div class="wrapper">
                                        <p class="course_name">
                                            {{
                                                course.dashboard_subject
                                                    .subject_name.subject_name
                                            }}
                                        </p>
                                        <span class="rate">
                                            <i class="fas fa-star"></i>
                                            <span>
                                                {{
                                                    course.dashboard_subject
                                                        .avg_rate
                                                }}
                                            </span>
                                        </span>
                                    </div>
                                    <div class="wrapper">
                                        <p class="price text-left">
                                            {{ course.dashboard_subject.price }}
                                            {{
                                                course.dashboard_subject
                                                    .currency
                                            }}
                                        </p>

                                        <p
                                            class="status"
                                            v-if="course.is_payment"
                                        >
                                            <i class="fas fa-check-circle"></i>
                                            {{
                                                $t("TITLES.already_participant")
                                            }}
                                        </p>
                                    </div>
                                </template>
                            </ImagedCard>
                        </router-link>
                        <!-- END:: COURSE CARD -->
                    </div>
                    <!-- END:: REGISTERED COURSE CARD -->
                </div>
            </div>
            <!-- START:: PAGENATION FOR TEACHER-->
            <Pagenation
                :last_page="last_page"
                :current_page="current_page"
                @pagenationClick="pagenationClick"
            ></Pagenation>
            <!-- END:: PAGENATION FOR TEACHER-->
        </div>
    </div>
</template>

<script>
// START:: IMPORTING VUEX HELPERS
import { mapGetters } from "vuex";
// END:: IMPORTING VUEX HELPERS

// START:: IMPORTING BREADCRUMB
import Breadcrumb from "../../components/structure/TheBreadcrumb.vue";
// END:: IMPORTING BREADCRUMB

// START:: IMPORTING TABS
import Tabs from "../../components/structure/Tabs.vue";
// END:: IMPORTING TABS
// START:: IMPORTING EMPTY MESSAGES
import EmptyCoursesMessage from "../../components/ui/emptyMessages/EmptyCoursesMessage.vue";
// END:: IMPORTING EMPTY MESSAGES
// START:: IMPORTING LOADERS
import MainLoader from "../../components/ui/loaders/MainLoader.vue";
// END:: IMPORTING LOADERS
// START:: IMPORTING UI IMAGED CARD
import ImagedCard from "../../components/ui/ImagedCard.vue";
// END:: IMPORTING UI IMAGED CARD
// START:: PAGENATION
import Pagenation from "../../components/ui/pagenation";
// END:: PAGENATION
export default {
    name: "MyCourses",

    components: {
        Breadcrumb,
        Tabs,
        ImagedCard,
        EmptyCoursesMessage,
        MainLoader,
        Pagenation,
    },

    computed: {
        // START:: VUEX GET AUTHENTICATED USER DATA
        ...mapGetters("AuthenticationModule", ["getAuthenticatedUserData"]),
        // END:: VUEX GET AUTHENTICATED USER DATA
    },

    data() {
        return {
            // START:: HANDLING SKELETON LOADER APPERANCE
            isLoading: false,
            // END:: HANDLING SKELETON LOADER APPERANCE

            // START:: USER TYPE
            userType: localStorage.getItem("elmo3lm_elmosa3d_user_type"),
            // END:: USER TYPE

            // START:: TABS CONTENT
            tabsContent: [
                {
                    tab: this.$t("TABS.online_courses"),
                    key_name: "online_courses",
                },
                // {
                //     tab: this.$t("TABS.registered_courses"),
                //     key_name: "registered_courses",
                // }
            ],
            // END:: TABS CONTENT

            // START:: REGISTERED COURSES DATA
            registeredCourses: [],
            // END:: REGISTERED COURSES DATA

            // START:: REGISTERED COURSES DATA
            onlineCourses: [],
            // END:: REGISTERED COURSES DATA

            // START:: TEACHER SUBJECTS
            teacherSubjects: [],
            // END:: TEACHER SUBJECTS
            // START:: PAGENATION
            currentTab: "registered_courses",
            last_page: null,
            current_page: 1,
            // END:: PAGENATION
        };
    },

    methods: {
        // START:: GET MY COURSES
        getItems(item) {
            this.currentTab = item;
            this.getMyCourses();
        },
        getMyCourses() {
            this.isLoading = true;
            if (this.userType == "teacher") {
                this.$axios
                    .get("user/subscription?page=" + this.current_page, {
                        headers: {
                            Authorization:
                                "Bearer " +
                                localStorage.getItem(
                                    "elmo3lm_elmosa3d_user_token"
                                ),
                            "Accept-language": localStorage.getItem(
                                "elmo3lm_elmosa3d_app_lang"
                            ),
                            "cache-control": "no-cache",
                            Accept: "application/json",
                        },
                    })
                    .then((res) => {
                        if (this.getAuthenticatedUserData.type == "teacher") {
                            this.teacherSubjects = res.data.data;
                            this.last_page = res.data.meta.last_page;
                        }
                        this.isLoading = false;
                    });
            } else {
                if (this.currentTab == "registered_courses") {
                    this.$axios(`student/my-courses?subject_kind=record`, {
                        headers: {
                            Authorization:
                                "Bearer " +
                                localStorage.getItem(
                                    "elmo3lm_elmosa3d_user_token"
                                ),
                            "Accept-language": localStorage.getItem(
                                "elmo3lm_elmosa3d_app_lang"
                            ),
                            "cache-control": "no-cache",
                            Accept: "application/json",
                        },
                    }).then((res) => {
                        this.registeredCourses = res.data.data;
                        this.last_page = res.data.meta.last_page;
                        this.isLoading = false;
                    });
                    // ============ END:: RECORD CORSES
                } else if (this.currentTab == "online_courses") {
                    // ============ START:: ONLINE CORSES
                    this.$axios(`student/my-courses?subject_kind=online`, {
                        headers: {
                            Authorization:
                                "Bearer " +
                                localStorage.getItem(
                                    "elmo3lm_elmosa3d_user_token"
                                ),
                            "Accept-language": localStorage.getItem(
                                "elmo3lm_elmosa3d_app_lang"
                            ),
                            "cache-control": "no-cache",
                            Accept: "application/json",
                        },
                    }).then((res) => {
                        this.onlineCourses = res.data.data;
                        this.last_page = res.data.meta.last_page;
                        this.isLoading = false;
                    });
                    // ============ END:: ONLINE CORSES
                }
            }
        },
        // END:: GET MY COURSES
        // START:: PAGENATION
        pagenationClick(pageNum) {
            this.current_page = pageNum;
            this.getMyCourses();
        },
        // END:: PAGENATION
    },

    mounted() {
        // START:: FIRE METHODS
        this.getMyCourses();
        // END:: FIRE METHODS
    },
};
</script>
