<template>
    <div class="course_details_group multi_columns my-3">
        <div class="content_wrapper">
            <!-- START:: COURSE TITLE -->
            <h3 class="title">
                {{ subjectName }}
                <span class="start_date_title" v-if="acadimicYear != null">
                    {{ acadimicYear.name }}
                </span>
            </h3>
            <h4 class="duration">
                <!-- <span>
                    {{ duration.day }}
                    {{ $t("TITLES.day") }}
                </span> -->

                <!-- <span>
                    {{ duration }}
                    {{ $t("TITLES.hour") }}
                </span> -->

                <!-- <span v-if="duration.minute != 0">
                    {{ duration.minute }}
                    {{ $t("TITLES.minute") }}
                </span>
                <span v-if="duration.second != 0">
                    {{ duration.second }}
                    {{ $t("TITLES.second") }}
                </span> -->
            </h4>
            <!-- END:: COURSE DURATION -->
            <!-- START:: COURSE START DATE -->
            <div class="start_date_wrapper">
                <span class="start_date_title">
                    {{ $t("TITLES.course_start_date") }}:
                </span>
                <span class="start_date">
                    {{ lessons.listens[0].start_date }}
                    <!-- {{ startDate }} -->
                </span>
            </div>
            <!-- END:: COURSE START DATE -->
        </div>

        <div class="content_wrapper">
            <div class="course_rate">
                <span class="title">
                    {{ $t("TITLES.ratings") }}
                </span>
                <span class="rate">
                    <i class="fas fa-star"></i>
                    <span>
                        {{ courseRate }}
                    </span>
                </span>
            </div>
            <div class="price" v-if="courseType == 'online'">
                <h3
                    v-if="
                        coursePriceAfterDiscount &&
                        coursePriceAfterDiscount != 0
                    "
                >
                    <span class="before_discount">
                        {{ coursePrice }}
                    </span>
                    <span>
                        {{ coursePriceAfterDiscount }}
                    </span>
                    <span v-if="courseCurrency">
                        {{ courseCurrency }}
                    </span>
                    <span v-else>جنيه مصري </span>
                </h3>
                <h3 v-else>
                    <span>
                        {{ coursePrice }}
                    </span>
                    <span v-if="courseCurrency">
                        {{ courseCurrency }}
                    </span>
                    <span v-else>جنيه مصري </span>
                </h3>
            </div>
        </div>

        <div class="content_wrapper full_width">
            <!-- START:: COURSE ACTIONS BUTTONS -->
            <div class="actions_buttons_wrapper">
                <!-- START:: EDIT COURSE BUTTON -->
                <router-link
                    v-if="routeName == 'teacher_my_course'"
                    :to="`/edit-course/${courseId}`"
                    class="edit_course_btn"
                >
                    {{ $t("BUTTONS.edit_course") }}
                </router-link>
                <!-- END:: EDIT COURSE BUTTON -->

                <!-- START:: SHARE BUTTON -->
                <div class="share_btns_group">
                    <button class="share_btn" @click.stop="toggleShareBtnsMenu">
                        <i class="fal fa-share-alt"></i>
                    </button>

                    <transition mode="out-in" name="fadeInUp">
                        <ul
                            class="social_share_btns"
                            v-if="shareBtnsMenuIsOpen"
                        >
                            <li class="single_share_btn">
                                <button @click="toggleShareBtnsMenu">
                                    <ShareNetwork
                                        network="facebook"
                                        :url="`https://elmo3lm-elmosa3d/#/${$route.path}`"
                                        :title="subjectName"
                                        :description="subjectDescription"
                                        hashtags="فايرا"
                                    >
                                        <img
                                            src="../../assets/media/icons/social/facebook.svg"
                                            alt="Facebook"
                                            width="35"
                                            height="35"
                                        />
                                    </ShareNetwork>
                                </button>
                            </li>

                            <li class="single_share_btn">
                                <button @click="toggleShareBtnsMenu">
                                    <ShareNetwork
                                        network="twitter"
                                        :url="`https://elmo3lm-elmosa3d/#/${$route.path}`"
                                        :title="subjectName"
                                        :description="subjectDescription"
                                        hashtags="فايرا"
                                    >
                                        <img
                                            src="../../assets/media/icons/social/Twitter.svg"
                                            alt="Twitter"
                                            width="35"
                                            height="35"
                                        />
                                    </ShareNetwork>
                                </button>
                            </li>

                            <li class="single_share_btn">
                                <button @click="toggleShareBtnsMenu">
                                    <ShareNetwork
                                        network="whatsapp"
                                        :url="`https://elmo3lm-elmosa3d/#/${$route.path}`"
                                        :title="subjectName"
                                        :description="subjectDescription"
                                        hashtags="فايرا"
                                    >
                                        <img
                                            src="../../assets/media/icons/social/whatsapp.svg"
                                            alt="Twitter"
                                            width="35"
                                            height="35"
                                        />
                                    </ShareNetwork>
                                </button>
                            </li>
                        </ul>
                    </transition>
                </div>
                <!-- END:: SHARE BUTTON -->

                <!-- START:: DELETE COURSE BUTTON -->
                <button
                    class="delete_course_btn"
                    v-if="
                        routeName == 'teacher_my_course' &&
                        courseType == 'record'
                    "
                    @click="toggleDeleteModal"
                >
                    <i class="fal fa-trash-alt"></i>
                </button>
                <!-- END:: DELETE COURSE BUTTON -->

                <!-- START:: ADD TO FAVORITE BUTTON -->
                <button
                    class="add_to_fav_btn"
                    @click="favClick"
                    v-show="
                        (routeName == 'course_details_of_teacher' ||
                            routeName == 'student_course_details') &&
                        !isFav
                    "
                >
                    <i class="fal fa-heart"></i>
                </button>
                <button
                    class="add_to_fav_btn"
                    @click="favClick"
                    v-show="
                        (routeName == 'course_details_of_teacher' ||
                            routeName == 'student_course_details') &&
                        isFav
                    "
                >
                    <i class="fas fa-heart"></i>
                </button>
                <!-- END:: ADD TO FAVORITE BUTTON -->
                <!-- START:: GO TO QUIZ ROUTE -->
                <router-link
                    :to="`/quiz/${courseType}/${courseId}`"
                    v-if="this.registeredUserType == 'student'"
                >
                    <button class="quiz_btn w-100" :title="`Quiz`">
                        <span
                            class="fs-4 p-5 font-weight-bold"
                            :style="{ color: 'darkblue' }"
                            >{{ $t("BUTTONS.quiz") }}</span
                        >
                    </button>
                </router-link>

                <!-- <router-link :to="`/show-quiz/${courseType}/${courseId}`" v-if="this.registeredUserType == 'teacher'">
                    <button class="quiz_btn w-100" :title="`Quiz`">
                        <span
                            class="fs-4 p-5 font-weight-bold"
                            :style="{ color: 'darkblue' }"
                            >{{ $t("BUTTONS.quiz") }}</span
                        >
                    </button>
                </router-link> -->
                <!-- END:: GO TO QUIZ ROUTE -->
                <!-- START:: REGISTER COURSE ROUTE -->
                <router-link
                    v-if="
                        courseType == 'online' &&
                        (routeName == 'course_details_of_teacher' ||
                            routeName == 'student_course_details')
                    "
                    :to="'/course-payment/' + courseId"
                    class="register_course"
                >
                    {{ $t("BUTTONS.register_course") }}
                </router-link>
                <!-- END:: REGISTER COURSE ROUTE -->
                <!-- START:: REGISTER COURSE ROUTE -->
                <!-- <button
                    v-if="
                        courseType == 'online' &&
                        (routeName == 'course_details_of_teacher' ||
                            routeName == 'student_course_details') &&
                        !courseIsSubscribed
                    "
                    @click="payMob"
                    class="register_course"
                    :class="{ disabled: lessonsData?.length == 0 }"
                >
                    {{ $t("BUTTONS.register_course") }}
                </button> -->
                <!-- END:: REGISTER COURSE ROUTE -->
            </div>
            <!-- END:: COURSE ACTIONS BUTTONS -->
        </div>

        <!-- START:: DELETE MODAL -->
        <DeleteModal
            :modalApperanceData="deleteModalIsOpen"
            @controleModalApperance="toggleDeleteModal"
        />
        <!-- END:: DELETE MODAL -->
        <!-- START:: PAY MODAL -->
        <div class="pay_wrapper" v-if="false">
            <BaseModal
                :show="modalApperanceData"
                @controleModalApperance="controleModalApperance"
            >
                <template #modal>
                    <iframe
                        width="100%"
                        height="800"
                        :src="`https://accept.paymob.com/api/acceptance/iframes/${iframe_id}?payment_token=${token}`"
                    ></iframe>
                </template>
            </BaseModal>
        </div>
        <!-- END:: PAY MODAL -->
    </div>
</template>

<script>
// START:: IMPORTING MODALS
import DeleteModal from "../../components/modals/DeleteModal.vue";
// END:: IMPORTING MODALS

// START:: IMPORTING BASE MODAL
import BaseModal from "../ui/BaseModal.vue";
// END:: IMPORTING BASE MODAL

export default {
    name: "CourseDetailsAndActionsButtons",
    props: [
        "courseId",
        "subjectName",
        "subjectDescription",
        "acadimicYear",
        "duration",
        "courseType",
        "routeName",
        "courseViewType",
        "startDate",
        "courseRate",
        "coursePrice",
        "coursePriceAfterDiscount",
        "courseCurrency",
        "courseIsFavorite",
        "courseIsSubscribed",
        "lessons",
    ],

    components: { DeleteModal, BaseModal },

    data() {
        return {
            // START:: MODALS HANDLING DATA
            deleteModalIsOpen: false,
            // END:: MODALS HANDLING DATA

            // START:: SHARE BUTTONS MENU APPERANCE  DATA
            shareBtnsMenuIsOpen: false,
            // END:: SHARE BUTTONS MENU APPERANCE  DATA

            isFav: this.courseIsFavorite,

            registeredUserType: localStorage.getItem(
                "elmo3lm_elmosa3d_user_type"
            ),
            lessonsData: [],
            showIframe: false,
            iframe_id: null,
            token: null,
            modalApperanceData: false,
        };
    },

    methods: {
        // START:: TOGGLE DELETE MODAL METHOD
        toggleDeleteModal() {
            this.deleteModalIsOpen = !this.deleteModalIsOpen;
        },
        // END:: TOGGLE DELETE MODAL METHOD

        // START:: TOGGLE SHARE BUTTONS MENU
        toggleShareBtnsMenu() {
            this.shareBtnsMenuIsOpen = !this.shareBtnsMenuIsOpen;
        },
        // end:: TOGGLE SHARE BUTTONS MENU

        // START:: FAV CLICK
        favClick() {
            // START:: SEND REQUEST
            const theData = new FormData();
            // START:: APPEND CONTACT DATA
            if (this.routeName == "course_details_of_teacher") {
                theData.append("fav_type", "dash_subject");
            }
            if (this.routeName == "student_course_details") {
                theData.append("fav_type", "subject");
            }
            theData.append("id", this.courseId);
            // END:: APPEND CONTACT DATA

            // START:: SEND REQUEST
            this.$axios
                .post("user/favourite", theData, {
                    headers: {
                        Authorization:
                            "Bearer " +
                            localStorage.getItem("elmo3lm_elmosa3d_user_token"),
                        "Accept-language": localStorage.getItem(
                            "elmo3lm_elmosa3d_app_lang"
                        ),
                        "cache-control": "no-cache",
                        Accept: "application/json",
                    },
                })
                .then((res) => {
                    this.isFav = res.data.data.is_fav;
                })
                .catch((err) => {
                    this.$iziToast.error({
                        timeout: 2000,
                        message: err.response.data.message,
                        messageSize: "22",
                        position: this.$t("iziToastConfigs.position"),
                        rtl: this.$t("iziToastConfigs.dir"),
                    });
                    return;
                });
            // END:: FAV CLICK
        },

        // START:: PAYMOB CLICK
        payMob() {
            // START:: SEND REQUEST
            const theData = new FormData();
            theData.append("price", this.totalPrice);
            // START:: APPEND CONTACT DATA
            // START:: SEND REQUEST
            this.$axios
                .post("website_paymob", theData, {
                    headers: {
                        Authorization:
                            "Bearer " +
                            localStorage.getItem("elmo3lm_elmosa3d_user_token"),
                        "Accept-language": localStorage.getItem(
                            "elmo3lm_elmosa3d_app_lang"
                        ),
                        "cache-control": "no-cache",
                        Accept: "application/json",
                    },
                })
                .then((res) => {
                    this.iframe_id = res.data.extra_data.online_iframe;
                    this.token = res.data.data.token;
                    this.modalApperanceData = true;
                    window.location.href = `https://accept.paymob.com/api/acceptance/iframes/${this.iframe_id}?payment_token=${this.token}`;
                })
                .catch((err) => {
                    this.$iziToast.error({
                        timeout: 2000,
                        message: err.response.data.message,
                        messageSize: "22",
                        position: this.$t("iziToastConfigs.position"),
                        rtl: this.$t("iziToastConfigs.dir"),
                    });
                    return;
                });
            // END:: FAV CLICK
        },

        // START:: CONTROLE MODAL APPERANCE
        controleModalApperance() {
            this.modalApperanceData = !this.modalApperanceData;
        },
        // END:: CONTROLE MODAL APPERANCE
    },

    created() {
        window.addEventListener("click", () => {
            this.shareBtnsMenuIsOpen = false;
        });
    },

    computed: {
        // START:: GET LESSONS DATA
        totalPrice() {
            return this.lessonsData?.reduce((sum, item) => sum + item.price, 0);
        },
        // END:: GET LESSONS DATA
    },
};
</script>

<style lang="scss">
.register_course {
    &.disabled {
        background-image: none !important;
        background-color: rgb(204, 204, 204, 0.7) !important;
        color: #ffffff;
        cursor: not-allowed;
        border: none;
        pointer-events: none;
        border: #c6c6c6 !important;
    }
}
</style>
