<template>
    <div class="store_wrapper">
        <!-- START:: BREADCRUMB -->
        <Breadcrumb>
            <template v-slot:page_title> {{ $t("TITLES.store") }} </template>
            <template v-slot:breadcrumb_current_page>
                {{ $t("TITLES.store") }}
            </template>
        </Breadcrumb>
        <!-- END:: BREADCRUMB -->
        <!-- START:: MAIN LOADER -->
        <MainLoader v-if="isLoading" />
        <!-- END:: MAIN LOADER -->
        <!-- START:: STORE CONTENT -->
        <div class="store_items_wrapper fadeIn" v-else>
            <div class="container">
                <EmptyStoreMessage
                    v-if="getStoreItems.length == 0"
                ></EmptyStoreMessage>
                <div class="row justify-content-center fadeIn" v-else>
                    <!-- START:: STORE ITEM CARD -->
                    <div
                        v-for="item in getStoreItems.setStoreItems"
                        :key="item.id"
                        class="col-lg-3 my-3 fadeIn"
                    >
                        <!-- START:: PRODUCT CARD -->
                        <button
                            class="store_item_card w-100"
                            @click="toggleStoreItemModal(item)"
                        >
                            <!-- START:: ITEM IMAGE -->
                            <div class="item_image_wrapper">
                                <img
                                    :src="item.product_image"
                                    alt="Store Item"
                                    width="120"
                                    height="120"
                                />
                            </div>
                            <!-- END:: ITEM IMAGE -->

                            <!-- START:: ITEM NAME -->
                            <div class="item_name">
                                <h3>{{ item.name }}</h3>
                            </div>
                            <!-- END:: ITEM NAME -->

                            <!-- START:: ITEM PRICE -->
                            <div class="item_price">
                                <h3>{{ item.price }} {{ item.currency }}</h3>
                            </div>
                            <!-- END:: ITEM PRICE -->
                        </button>
                        <!-- END:: PRODUCT CARD -->
                    </div>
                    <!-- END:: STORE ITEM CARD -->
                </div>
            </div>
            <!-- START:: PAGENATION FOR TEACHER-->
            <Pagenation
                :last_page="getStoreItems.last_page"
                :current_page="current_page"
                @pagenationClick="pagenationClick"
            ></Pagenation>
            <!-- END:: PAGENATION FOR TEACHER-->
        </div>
        <!-- END:: STORE CONTENT -->

        <!-- START:: STORE ITEM MODAL -->
        <StoreItemModal
            :modalApperanceData="storeItemModalIsOpen"
            :selectedItem="selectedStoreItem"
            @controleModalApperance="toggleStoreItemModal"
        />
        <!-- END:: STORE ITEM MODAL -->
    </div>
</template>

<script>
// START:: IMPORTING VUEX HELPERS
import { mapActions, mapGetters } from "vuex";
// END:: IMPORTING VUEX HELPERS

// START:: IMPORTING BREADCRUMB
import Breadcrumb from "../../components/structure/TheBreadcrumb.vue";
// END:: IMPORTING BREADCRUMB

// START:: IMPORTING MODALS
import StoreItemModal from "../../components/modals/StoreItemModal.vue";
// END:: IMPORTING MODALS
// START:: IMPORTING LOADERS
import MainLoader from "../../components/ui/loaders/MainLoader.vue";
// END:: IMPORTING LOADERS
// START:: IMPORTING EMPTY MESSAGES
import EmptyStoreMessage from "../../components/ui/emptyMessages/EmptyStoreMessage.vue";
// END:: IMPORTING EMPTY MESSAGES
// START:: PAGENATION
import Pagenation from "../../components/ui/pagenation";
// END:: PAGENATION
// import { updateMeta } from "@/plugins/updateMeta";
import logoImg from "../../assets/media/logo/logo.svg";
export default {
    name: "TheStore",

    metaInfo() {
        return {
            title: this.getStoreItems?.seoData
                ? this.getStoreItems?.seoData.title
                : "منصة فايرا التعليمية",
            meta: [
                {
                    name: "description",
                    content: this.getStoreItems?.seoData
                        ? this.getStoreItems?.seoData.description
                        : "منصة فايرا التعليمية",
                },
                {
                    property: "og:title",
                    content: this.getStoreItems?.seoData
                        ? this.getStoreItems?.seoData.title
                        : "منصة فايرا التعليمية",
                },
                {
                    property: "author",
                    content: this.getStoreItems
                        ? this.getStoreItems?.seoData.title
                        : "منصة فايرا التعليمية",
                },
                {
                    property: "keywords",
                    content: this.getStoreItems?.seoData
                        ? this.getStoreItems?.seoData.keywords
                        : "منصة فايرا التعليمية",
                },
                {
                    property: "image",
                    content: this.getStoreItems?.seoData
                        ? this.getStoreItems?.seoData.image
                        : logoImg,
                },
                {
                    property: "og:image",
                    content: this.getStoreItems?.seoData
                        ? this.getStoreItems?.seoData.image
                        : logoImg,
                },
                {
                    property: "robots",
                    content: "index,follow",
                },
            ],
        };
    },

    components: {
        Breadcrumb,
        StoreItemModal,
        MainLoader,
        EmptyStoreMessage,
        Pagenation,
    },

    computed: {
        // START:: STORE ITEMS
        ...mapGetters("StoreAndWishlistModule", ["getStoreItems"]),
        // END:: STORE ITEMS
    },

    data() {
        return {
            // START:: HANDLING SKELETON LOADER APPERANCE
            isLoading: true,
            // END:: HANDLING SKELETON LOADER APPERANCE

            // START:: MODALS HANDLING DATA
            storeItemModalIsOpen: false,
            // END:: MODALS HANDLING DATA

            // START:: SELECTED STORE ITEM
            selectedStoreItem: null,
            // END:: SELECTED STORE ITEM
            // START:: PAGENATION
            current_page: 1,
            // END:: PAGENATION

            // START:: SEO DATA
            seoData: null,
            // END:: SEO DATA
        };
    },

    methods: {
        ...mapActions("StoreAndWishlistModule", ["getProducts"]),
        // START:: TOGGLE STORE ITEM MODAL METHOD
        toggleStoreItemModal(selectedItem) {
            this.storeItemModalIsOpen = !this.storeItemModalIsOpen;
            // START:: SET MODAL ITEM TO SHOW
            this.selectedStoreItem = selectedItem;
            // END:: SET MODAL ITEM TO SHOW
        },
        // START:: PAGENATION
        pagenationClick(pageNum) {
            this.current_page = pageNum;
            this.getProducts(this.current_page);
            // if (this.getStoreItems?.seoData) {
            //     updateMeta(this.getStoreItems?.seoData);
            // }
        },
        // END:: PAGENATION
        // END:: TOGGLE STORE ITEM MODAL METHOD
    },

    mounted() {
        setTimeout(() => {
            this.isLoading = false;
            this.getProducts(this.current_page);
            // if (this.getStoreItems?.seoData) {
            //     updateMeta(this.getStoreItems?.seoData);
            // }
        }, 1000);
    },
};
</script>
