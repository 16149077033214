<template :key="$route.path">
    <div>
        <!-- START:: MAIN LOADER -->
        <MainLoader v-if="isLoading" />
        <!-- END:: MAIN LOADER -->

        <!-- START:: SECTION -->
        <div class="course_details_wrapper fadeIn" v-else>
            <!-- START:: IMAGE HEADER -->
            <ImageHeader>
                <template>
                    <div class="header_container">
                        <div class="container">
                            <div class="header_title_wrapper">
                                <h1>
                                    {{
                                        courseDetails?.subject_name
                                            ?.subject_name
                                    }}
                                </h1>
                            </div>
                        </div>
                    </div>
                </template>
            </ImageHeader>
            <!-- END:: IMAGE HEADER -->

            <div class="corses_details_content_wrapper">
                <div class="container">
                    <div class="row">
                        <!-- START:: COURSE DETAILS COLUMN -->
                        <div class="col-lg-6 my-3 order-2 order-md-1">
                            <CourseStaticInfoAndAttachments
                                :description="courseDetails.desc"
                                :goals="courseDetails.objectives"
                                :enrichments="courseDetails.enriching"
                                :attachments="
                                    $route.name == 'course_details_of_teacher'
                                        ? courseDetails.dash_attachments
                                        : courseDetails.attachments
                                "
                            />
                        </div>
                        <!-- END:: COURSE DETAILS COLUMN -->

                        <!-- START:: COURSE DETAILS COLUMN -->
                        <div class="col-lg-6 my-3 order-1 order-md-2">
                            <div class="course_details_column">
                                <!-- START:: COURSE INFO -->
                                <CourseDetailsAndActionsButtons
                                    :courseId="courseDetails.id"
                                    :subjectName="
                                        courseDetails?.subject_name
                                            ?.subject_name
                                    "
                                    :subjectDescription="courseDetails.desc"
                                    :acadimicYear="courseDetails.academic_year"
                                    :duration="courseDetails.diff_of_time"
                                    :courseType="courseType"
                                    :routeName="routeName"
                                    :startDate="courseDetails.start_date"
                                    :courseRate="courseDetails.avg_rate"
                                    :coursePrice="courseDetails.price"
                                    :coursePriceAfterDiscount="
                                        courseDetails.price_after_discount
                                    "
                                    :courseCurrency="courseDetails.currency"
                                    :courseViewType="courseViewType"
                                    :courseIsFavorite="courseDetails.is_fav"
                                    :courseIsSubscribed="
                                        courseDetails.is_subscribe
                                    "
                                    :lessons="courseDetails"
                                />
                                <!-- END:: COURSE INFO-->

                                <!-- START:: LESSONS -->
                                <CourseLessonsLinks
                                    :viewType="courseViewType"
                                    :courseType="courseType"
                                    :routeName="routeName"
                                    :lessonsList="courseDetails"
                                    @getDetailsAfterEdit="getCourseDetails"
                                />
                                <!-- END:: LESSONS -->
                            </div>
                            <!-- START:: INSTRUCTOR INFO -->
                            <CourseInstructor
                                v-if="
                                    routeName == 'student_course_details' ||
                                    routeName == 'parent_course_details'
                                "
                                :instructor="courseDetails.teacher"
                            />
                            <!-- END:: INSTRUCTOR INFO -->

                            <!-- START:: COURSE REGISTERED STUDENTS  -->
                            <CourseRegisteredStudents
                                :courseRegisteredStudents="
                                    courseDetails.subscription_data
                                "
                                v-if="routeName == 'teacher_my_course'"
                            />
                            <!-- END:: COURSE REGISTERED STUDENTS  -->
                        </div>
                        <!-- END:: COURSE DETAILS COLUMN -->

                        <!-- START:: RATINGS COLUMN -->
                        <div class="col-12 order-3">
                            <CourseRates :courseRates="courseRates" />
                        </div>
                        <!-- END:: RATINGS COLUMN -->

                        <!-- START:: SET RATING COLUMN -->
                        <div
                            class="col-12 order-4"
                            v-if="routeName != 'teacher_my_course'"
                        >
                            <SetCourseRate />
                        </div>
                        <!-- END:: SET RATING COLUMN -->
                    </div>
                </div>
            </div>
        </div>
        <!-- END:: SECTION -->
    </div>
</template>

<script>
// START:: IMPORTING VUEX HELPERS
import { mapGetters } from "vuex";
// END:: IMPORTING VUEX HELPERS

// START:: IMPORTING LOADERS
import MainLoader from "../../components/ui/loaders/MainLoader.vue";
// END:: IMPORTING LOADERS

// START:: IMPORTING COURSE DETAILS COMPS
import CourseStaticInfoAndAttachments from "../../components/courseDetailsDividedComps/CourseStaticInfoAndAttachments.vue";
import CourseDetailsAndActionsButtons from "../../components/courseDetailsDividedComps/CourseDetailsAndActionsButtons.vue";
import CourseLessonsLinks from "../../components/courseDetailsDividedComps/CourseLessonsLinks.vue";
import CourseInstructor from "../../components/courseDetailsDividedComps/CourseInstructor.vue";
import CourseRegisteredStudents from "../../components/courseDetailsDividedComps/CourseRegisteredStudents.vue";
import CourseRates from "../../components/courseDetailsDividedComps/CourseRates.vue";
import SetCourseRate from "../../components/courseDetailsDividedComps/SetCourseRate.vue";
// import { updateMeta } from "@/plugins/updateMeta";
import logoImg from "../../assets/media/logo/logo.svg";
// END:: IMPORTING COURSE DETAILS COMPS

// START:: IMPORTING IMAGE HEADER
import ImageHeader from "../../components/ui/ImageHeader.vue";
// END:: IMPORTING IMAGE HEADER

import commentImage from "../../assets/media/images/user_avatar.png";

export default {
    name: "CourseDetails",

    metaInfo() {
        return {
            title: this.seoData ? this.seoData.title : "منصة فايرا التعليمية",
            meta: [
                {
                    name: "description",
                    content: this.seoData
                        ? this.seoData.description
                        : "منصة فايرا التعليمية",
                },
                {
                    property: "og:title",
                    content: this.seoData
                        ? this.seoData.title
                        : "منصة فايرا التعليمية",
                },
                {
                    property: "author",
                    content: this.seoData
                        ? this.seoData.title
                        : "منصة فايرا التعليمية",
                },
                {
                    property: "keywords",
                    content: this.seoData
                        ? this.seoData.keywords
                        : "منصة فايرا التعليمية",
                },
                {
                    property: "image",
                    content: this.seoData ? this.seoData.image : logoImg,
                },
                {
                    property: "og:image",
                    content: this.seoData ? this.seoData.image : logoImg,
                },
                {
                    property: "robots",
                    content: "index,follow",
                },
            ],
        };
    },

    components: {
        CourseStaticInfoAndAttachments,
        CourseDetailsAndActionsButtons,
        CourseLessonsLinks,
        CourseInstructor,
        CourseRegisteredStudents,
        CourseRates,
        SetCourseRate,
        MainLoader,
        ImageHeader,
    },

    computed: {
        // START:: VUEX GET AUTHENTICATED USER DATA
        ...mapGetters("AuthenticationModule", ["getAuthenticatedUserData"]),
        // END:: VUEX GET AUTHENTICATED USER DATA

        // START::  COURSE VIEW TYPE
        courseViewType() {
            if (this.courseRoute.includes("teacher-view-course-details")) {
                return "teacher-view";
            } else {
                if (this.registeredUserType == "parent") {
                    return "parent-view";
                } else {
                    return "student-view";
                }
            }
        },
        // END::  COURSE VIEW TYPE
        // metaInfo() {
        //     return {
        //         title: this.seoData?.title || "منصة فايرا التعليمية",
        //         meta: [
        //             {
        //                 vmid: "author",
        //                 name: "author",
        //                 content: "Egypt Faira",
        //             },
        //             {
        //                 vmid: "description",
        //                 name: "description",
        //                 content:
        //                     this.seoData?.description || "منصة فايرا التعليمية",
        //             },
        //             {
        //                 vmid: "keywords",
        //                 name: "keywords",
        //                 content:
        //                     this.seoData?.keywords ||
        //                     "مدارس , طلاب, معلمين , دروس",
        //             },
        //             {
        //                 vmid: "image",
        //                 name: "image",
        //                 content: logoImg,
        //             },
        //             {
        //                 vmid: "og:image",
        //                 name: "og:image",
        //                 content: logoImg,
        //             },
        //         ],
        //     };
        // },
    },

    data() {
        return {
            // START:: HANDLING SKELETON LOADER APPERANCE
            isLoading: true,
            // END:: HANDLING SKELETON LOADER APPERANCE

            // START:: COURSE ROUTE
            courseRoute: this.$route.path,
            // END:: COURSE ROUTE

            // START:: COURSE TYPE DATA
            courseType: this.$route.params.type,
            routeName: this.$route.name,
            // END:: COURSE TYPE DATA

            // START:: REGISTERED USER TYPE
            registeredUserType: localStorage.getItem(
                "elmo3lm_elmosa3d_user_type"
            ),
            // END:: REGISTERED USER TYPE

            // START:: REGISTERED STUDENTS DATA
            registeredStudents: [
                {
                    id: 1,
                    image: commentImage,
                    name: "اسلام محمد ابراهيم",
                },
                {
                    id: 2,
                    image: commentImage,
                    name: "اسلام محمد ابراهيم",
                },
                {
                    id: 3,
                    image: commentImage,
                    name: "اسلام محمد ابراهيم",
                },
            ],
            // END:: REGISTERED STUDENTS DATA

            // START:: COURSE DETAILS DATA
            courseDetails: null,
            // END:: COURSE DETAILS DATA

            // START:: COURSE RATES DATA
            courseRates: null,
            // END:: COURSE RATES DATA

            // START:: SEO DATA
            seoData: null,
            // END:: SEO DATA: null,
        };
    },

    methods: {
        // START:: GET COURSE DATA
        getCourseDetails() {
            if (
                this.registeredUserType == "teacher" &&
                this.routeName == "course_details_of_teacher"
            ) {
                this.$axios
                    .get(`teacher/category/subject/${this.$route.params.id}`, {
                        headers: {
                            Authorization:
                                "Bearer " +
                                localStorage.getItem(
                                    "elmo3lm_elmosa3d_user_token"
                                ),
                            "Accept-language": localStorage.getItem(
                                "elmo3lm_elmosa3d_app_lang"
                            ),
                            "cache-control": "no-cache",
                            Accept: "application/json",
                        },
                    })
                    .then((res) => {
                        this.isLoading = false;
                        this.courseDetails = res.data.data;
                        this.seoData = res.data.home_seo_meta;
                        // updateMeta(this.seoData);
                    });
            } else if (
                this.registeredUserType == "teacher" &&
                this.routeName == "teacher_my_course"
            ) {
                this.$axios
                    .get(`teacher/subject/show/${this.$route.params.id}`, {
                        headers: {
                            Authorization:
                                "Bearer " +
                                localStorage.getItem(
                                    "elmo3lm_elmosa3d_user_token"
                                ),
                            "Accept-language": localStorage.getItem(
                                "elmo3lm_elmosa3d_app_lang"
                            ),
                            "cache-control": "no-cache",
                            Accept: "application/json",
                        },
                    })
                    .then((res) => {
                        console.log(res.data.data);

                        this.isLoading = false;
                        this.courseDetails = res.data.data;
                        this.seoData = res.data.home_seo_meta;
                        // updateMeta(this.seoData);
                    });
            } else if (
                this.registeredUserType == "student" &&
                this.routeName == "student_course_details"
            ) {
                this.$axios
                    .get(`student/course/${this.$route.params.id}`, {
                        headers: {
                            Authorization:
                                "Bearer " +
                                localStorage.getItem(
                                    "elmo3lm_elmosa3d_user_token"
                                ),
                            "Accept-language": localStorage.getItem(
                                "elmo3lm_elmosa3d_app_lang"
                            ),
                            "cache-control": "no-cache",
                            Accept: "application/json",
                        },
                    })
                    .then((res) => {
                        this.isLoading = false;
                        this.courseDetails = res.data.data;
                        this.seoData = res.data.home_seo_meta;
                        // updateMeta(this.seoData);
                    });
            } else if (this.registeredUserType == "parent") {
                this.$axios
                    .get(`parent/subject/${this.$route.params.id}`, {
                        headers: {
                            Authorization:
                                "Bearer " +
                                localStorage.getItem(
                                    "elmo3lm_elmosa3d_user_token"
                                ),
                            "Accept-language": localStorage.getItem(
                                "elmo3lm_elmosa3d_app_lang"
                            ),
                            "cache-control": "no-cache",
                            Accept: "application/json",
                        },
                    })
                    .then((res) => {
                        this.isLoading = false;
                        this.courseDetails = res.data.data;
                        this.seoData = res.data.home_seo_meta;
                        // updateMeta(this.seoData);
                    });
            }
        },
        // END:: GET COURSE DATA

        // START:: GET COURSE RATES
        getCourseRates() {
            this.$axios
                .get(`user/rate/${this.$route.params.id}`, {
                    headers: {
                        Authorization:
                            "Bearer " +
                            localStorage.getItem("elmo3lm_elmosa3d_user_token"),
                        "Accept-language": localStorage.getItem(
                            "elmo3lm_elmosa3d_app_lang"
                        ),
                        "cache-control": "no-cache",
                        Accept: "application/json",
                    },
                })
                .then((res) => {
                    this.courseRates = res.data.data;
                    // this.seoData = res.data.home_seo_meta;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        // END:: GET COURSE RATES
    },

    mounted() {
        // START:: FIRE METHODS
        this.getCourseDetails();
        this.getCourseRates();
        // END:: FIRE METHODS

        // setTimeout(() => {
        //     this.isLoading = false;
        // }, 1500);
    },
};
</script>
